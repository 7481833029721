import { DairyProcessingMetrics } from "../../types"
import { calculateCheeseWheyEnabled } from "./calculateCheeseWheyEnabled"
import { calculateCottageCheeseWheyWeight } from "./calculateCottageCheeseWheyWeight"

export function calculateCheeseAndCottage({
  dairyProcessingMetrics,
  cottageCheeseWhey,
  cheeseWhey,
  currentWeight,
  _currentWeight,
}: {
  dairyProcessingMetrics: DairyProcessingMetrics
  cottageCheeseWhey: boolean
  cheeseWhey: boolean
  currentWeight: number
  _currentWeight: number
}) {
  let temporalDairyProcessingMetrics = dairyProcessingMetrics
  if (cottageCheeseWhey) {
    temporalDairyProcessingMetrics = calculateCottageCheeseWheyWeight({
      state: dairyProcessingMetrics,
      currentWeight,
      _currentWeight,
    })
  }

  if (cheeseWhey) {
    temporalDairyProcessingMetrics = calculateCheeseWheyEnabled({
      state: dairyProcessingMetrics,
      currentWeight,
      _currentWeight,
    })
  }

  return temporalDairyProcessingMetrics
}
