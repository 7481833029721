import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { ProductTypeDto } from "api/model"
import { yesOrNo } from "shared/utils/yesOrNo"

export const createColumns = (sortedInfo: SorterResult<ProductTypeDto>) => {
  const cols: ColumnsType<ProductTypeDto> = [
    {
      title: "Id",
      dataIndex: "mockId",
      key: "id",
      width: 60,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      sorter: (a, b) => {
        if (a.mockId && b.mockId) {
          return a.mockId - b.mockId
        }
        return 0
      },
    },
    {
      title: "Наименование продукта",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      render: (_, record) => (
        <span data-system={record.systemName} title={record.systemName}>
          {record.name}
        </span>
      ),
    },
    {
      title: "Расход на 1 т (да/нет)",
      dataIndex: "mixConsumption",
      key: "mixConsumption",
      render: (_, record) => {
        return <>{yesOrNo(record.mixConsumption)}</>
      },
      sorter: (a, b) => Number(a.mixConsumption) - Number(b.mixConsumption),
      sortOrder: sortedInfo.columnKey === "mixConsumption" ? sortedInfo.order : null,
    },
    // временно скрыта по просьбе заказчика
    // {
    //   title: "Расчет обрата для низкожирного творога",
    //   dataIndex: "lowFatMilkVolume",
    //   key: "lowFatMilkVolume",
    //   render: (_, record) => {
    //     return <>{yesOrNo(record.lowFatMilkVolume)}</>
    //   },
    //   sorter: (a, b) => Number(a.lowFatMilkVolume) - Number(b.lowFatMilkVolume),
    //   sortOrder: sortedInfo.columnKey === "lowFatMilkVolume" ? sortedInfo.order : null,
    // },
    {
      title: "Расчет творожной сыворотки",
      dataIndex: "cottageCheeseWhey",
      key: "cottageCheeseWhey",
      render: (_, record) => {
        return <>{yesOrNo(record.cottageCheeseWhey)}</>
      },
      sorter: (a, b) => Number(a.cottageCheeseWhey) - Number(b.cottageCheeseWhey),
      sortOrder: sortedInfo.columnKey === "cottageCheeseWhey" ? sortedInfo.order : null,
    },
    {
      title: "Расчет подсырной сыворотки",
      dataIndex: "cheeseWhey",
      key: "cheeseWhey",
      render: (_, record) => {
        return <>{yesOrNo(record.cheeseWhey)}</>
      },
      sorter: (a, b) => Number(a.cheeseWhey) - Number(b.cheeseWhey),
      sortOrder: sortedInfo.columnKey === "cheeseWhey" ? sortedInfo.order : null,
    },
    {
      title: "Расчет пахты",
      dataIndex: "buttermilk",
      key: "buttermilk",
      render: (_, record) => {
        return <>{yesOrNo(record.buttermilk)}</>
      },
      sorter: (a, b) => Number(a.buttermilk) - Number(b.buttermilk),
      sortOrder: sortedInfo.columnKey === "buttermilk" ? sortedInfo.order : null,
    },
    // временно скрыта по просьбе заказчика
    // {
    //   title: "Действие",
    //   key: "action",
    //   width: 150,
    //   render: (_, record) => {
    //     return (
    //       <Space size='middle'>
    //         <Button onClick={() => action(record)} type='primary' icon={<EditOutlined />}>
    //           Изменить
    //         </Button>
    //       </Space>
    //     )
    //   },
    // },
  ]

  return cols
}
