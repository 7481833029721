import { RecipeAssortment } from "../types"
import {
  calculateRecipeMaterials,
  calculateRecipeMaterialsByProduct,
} from "./calculateRecipeMaterials"
import { calculateRecipeRaw } from "./calculateRecipeRaw"
import { streamToVolumeConfigs, volumeToStreamConfigs } from "./utils/configs"

export const calculateRecipes = (
  inputData: Record<string, number>,
  assortments: RecipeAssortment[],
) => {
  const { packagingMaterials, basicMaterials } = calculateRecipeMaterials(assortments)

  const { basicMaterialsInProducts, packagingMaterialsInProducts } =
    calculateRecipeMaterialsByProduct(assortments)

  const recipeRaw = calculateRecipeRaw(inputData, assortments, streamToVolumeConfigs, "amount")

  const reversedRecipeRaw = calculateRecipeRaw(
    inputData,
    assortments,
    volumeToStreamConfigs,
    "amount",
  )

  const recipeRawLoss = calculateRecipeRaw(
    inputData,
    assortments,
    streamToVolumeConfigs,
    "lossPercentage",
  )

  const reversedRecipeRawLoss = calculateRecipeRaw(
    inputData,
    assortments,
    volumeToStreamConfigs,
    "lossPercentage",
  )

  return {
    packagingMaterials,
    basicMaterials,
    basicMaterialsInProducts,
    packagingMaterialsInProducts,
    recipeRaw,
    recipeRawLoss,
    reversedRecipeRaw,
    reversedRecipeRawLoss,
  }
}
