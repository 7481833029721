import React from "react"
import { NavLink } from "react-router-dom"

import { Button, Layout, Menu, theme } from "antd"
import hash from "object-hash"

const { Content, Sider } = Layout

type MenuLayoutProps = {
  children?: React.ReactNode
  menuItems: {
    label: string
    key: React.Key
    icon?: React.ReactNode
    appendSeparator?: boolean
    disable?: boolean
  }[]
}

export function MenuLayout({ children, menuItems }: MenuLayoutProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Layout>
      <Sider width='fit-content'>
        <Menu style={{ paddingTop: "20px" }} theme='dark'>
          {menuItems?.map((menuItem, index) => {
            const number = index + 1

            const showNumber = index < 6
            return (
              <React.Fragment key={hash(menuItem)}>
                <NavLink to={menuItem?.key.toString()}>
                  {(event) => {
                    return (
                      <Button
                        disabled={menuItem.disable}
                        icon={menuItem.icon}
                        style={{
                          width: "100%",
                          padding: "20px 14px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          borderRadius: 0,
                          border: 0,
                          color: event.isActive ? "white" : "gray",
                        }}
                        type={event.isActive ? "primary" : undefined}
                        ghost={!event.isActive}
                      >
                        {showNumber && number + ". "}
                        {menuItem.label}
                      </Button>
                    )
                  }}
                </NavLink>

                {menuItem.appendSeparator && (
                  <div style={{ height: "1px", background: "gray", margin: "10px 0" }} />
                )}
              </React.Fragment>
            )
          })}
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: "16px" }}>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
