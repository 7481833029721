import { DairyProcessingMetrics } from "../../types"

/**
 * @description Расчет требуемого количества обрата для обезжиренных продуктов (в потоке и объемная)
 */
export const calculateForFreeFatProducts = (
  state: DairyProcessingMetrics,
  _currentWeight: number,
) => {
  return {
    ...state,
    freeFatMilkVolumeForFreeFatProducts: state.freeFatMilkVolumeForFreeFatProducts + _currentWeight,
  }
}
