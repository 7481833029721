import { RecipeBasicMaterialDto, RecipePackagingMaterialDto } from "api/model"

import { RecipeAssortment } from "../types"

type CommonRecipeMaterial = {
  expenditure?: {
    amount: number
    lossPercentage?: number
  }
}

const normalizationLocales = {
  stream: "нормализация в потоке",
  volume: "объёмная нормализация",
} as const

const getMaterialConsumption = (amount = 0, quantity = 0) => {
  return Number((amount * (quantity / 1000)).toFixed(5))
}

const processMaterials = <T extends CommonRecipeMaterial>(
  materials: T[] | undefined = [],
  currentWeight: number,
): T[] => {
  return materials.map((material) => ({
    ...material,
    expenditure: {
      amount: getMaterialConsumption(material.expenditure?.amount ?? 0, currentWeight),
      lossPercentage: getMaterialConsumption(
        material.expenditure?.lossPercentage ?? 0,
        currentWeight,
      ),
    },
  }))
}

const processMaterialsList = <T extends RecipeBasicMaterialDto | RecipePackagingMaterialDto>(
  flatMaterials: T[],
) => {
  const materialsMap = new Map<string, T>()

  for (const material of flatMaterials) {
    if (material === undefined) continue
    let key = ""

    if ("basicMaterial" in material) {
      key = String(material.basicMaterial?.id) ?? ""
    } else if ("packagingMaterial" in material) {
      key = String(material.packagingMaterial?.id) ?? ""
    }

    const current = materialsMap.get(key)

    if (current) {
      const materialAmount = material.expenditure?.amount ?? 0
      const materialLoss = material.expenditure?.lossPercentage ?? 0
      const currentAmount = current?.expenditure?.amount ?? 0
      const currentLoss = current?.expenditure?.lossPercentage ?? 0

      const updated = {
        ...current,
        expenditure: {
          amount: currentAmount + materialAmount,
          lossPercentage: currentLoss + materialLoss,
        },
      }
      materialsMap.set(key, updated)
    } else {
      materialsMap.set(key, material)
    }
  }

  return Array.from(materialsMap.values())
}

// TODO: refactor
export const calculateRecipeMaterials = (assortments: RecipeAssortment[]) => {
  const flatBasicMaterials: RecipeBasicMaterialDto[] = []
  const flatPackagingMaterials: RecipePackagingMaterialDto[] = []

  for (const assortment of assortments) {
    const processedBm = processMaterials(
      assortment.selectedRecipe?.basicMaterials,
      assortment.currentWeight,
    )
    const processedPm = processMaterials(
      assortment.selectedRecipe?.packagingMaterials,
      assortment.currentWeight,
    )

    flatBasicMaterials.push(...processedBm)
    flatPackagingMaterials.push(...processedPm)
  }

  return {
    basicMaterials: processMaterialsList(flatBasicMaterials),
    packagingMaterials: processMaterialsList(flatPackagingMaterials),
  }
}

export const calculateRecipeMaterialsByProduct = (assortments: RecipeAssortment[]) => {
  const packagingMaterialsInProducts: {
    name: string
    packagingMaterials: RecipePackagingMaterialDto[]
  }[] = []

  const basicMaterialsInProducts: {
    name: string
    basicMaterials: RecipeBasicMaterialDto[]
  }[] = []

  for (const assortment of assortments) {
    if (!assortment.selectedRecipe) continue

    packagingMaterialsInProducts.push({
      name: `${assortment.product.name} (${assortment.product.fatPercentage}%) - ${
        normalizationLocales[assortment.normalizationMethod]
      }`,
      packagingMaterials: processMaterials(
        assortment.selectedRecipe.packagingMaterials,
        assortment.currentWeight,
      ),
    })

    basicMaterialsInProducts.push({
      name: `${assortment.product.name} (${assortment.product.fatPercentage}%) - ${
        normalizationLocales[assortment.normalizationMethod]
      }`,
      basicMaterials: processMaterials(
        assortment.selectedRecipe.basicMaterials,
        assortment.currentWeight,
      ),
    })
  }

  return { packagingMaterialsInProducts, basicMaterialsInProducts }
}
