import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { EditOutlined } from "@ant-design/icons"
import { Button, Space, Tag } from "antd"
import { UserDto } from "api/model"
import { Role } from "features/auth/model"
import { formatDate } from "shared/utils/dateTime"

const rolesNames: Record<Role, string> = {
  admin: "Администратор",
  master: "Мастер",
  technologist: "Технолог",
}

export const createColumns = (
  sortedInfo: SorterResult<UserDto>,
  action: (record: UserDto) => void,
) => {
  const cols: ColumnsType<UserDto> = [
    {
      title: "Логин",
      dataIndex: "login",
      key: "login",
      sorter: (a, b) => (a.login ?? "").localeCompare(b.login ?? ""),
      sortOrder: sortedInfo.columnKey === "login" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Роли",
      dataIndex: "roles",
      key: "roles",
      ellipsis: true,
      render: (string: UserDto["roles"]) =>
        string?.split(" ").map((item, i) => <Tag key={i}>{rolesNames[item as Role]}</Tag>),
    },
    {
      title: "Дата создания",
      dataIndex: "created",
      key: "created",
      ellipsis: true,
      render: formatDate,
    },
    {
      title: "Последний вход",
      dataIndex: "lastLogin",
      key: "lastLogin",
      ellipsis: true,
      render: formatDate,
    },
    {
      title: "Действие",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Space size='middle'>
            <Button onClick={() => action(record)} type='primary' icon={<EditOutlined />}>
              Изменить
            </Button>
          </Space>
        )
      },
    },
  ]

  return cols
}
