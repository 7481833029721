import { useState } from "react"

import type { FormInstance } from "antd"

export const useValidateServerField = <T>({
  form,
  fieldName,
  error,
}: {
  form: FormInstance<T>
  fieldName: string
  error: string
}) => {
  const [serverFieldError, setServerFieldError] = useState(undefined)

  const setFormError = () => {
    form.setFields([
      {
        name: fieldName,
        errors: [error],
      },
    ])

    setServerFieldError(form.getFieldValue(["name"]))
  }

  const handleCheckField = (value: string) => {
    if (serverFieldError && value !== serverFieldError) {
      form.setFields([
        {
          name: fieldName,
          errors: [],
        },
      ])

      setServerFieldError(undefined)
    }
  }

  return {
    handleCheckField,
    setFormError,
  }
}
