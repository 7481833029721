import { useNavigate } from "react-router-dom"

import { Card, Space } from "antd"
import { AuthForm } from "features/auth/ui/AuthForm"
import { clientModuleRoutes } from "shared/constants/routes"

export function LoginPage() {
  const navigate = useNavigate()
  return (
    <Space
      direction='vertical'
      size='large'
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#f5f5f5",
      }}
    >
      <Card title='Вход' style={{ width: "500px", maxWidth: "100%" }}>
        <AuthForm onLogin={() => navigate(clientModuleRoutes.recipes.assortment.root)} />
      </Card>
    </Space>
  )
}
