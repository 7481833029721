export const getCurrentWeightAndFat = ({
  mixConsumptionEnabled,
  currentWeight,
  mixConsumption = 0,
  currentFatMix,
  currentFatProduct,
  isButter,
}: {
  mixConsumptionEnabled: boolean
  currentWeight: number
  mixConsumption?: number
  currentFatMix: number
  currentFatProduct: number
  isButter: boolean
}) => {
  const _currentWeight = mixConsumptionEnabled
    ? (currentWeight * mixConsumption) / 1000
    : currentWeight

  let _currentFat = 0

  if (isButter) {
    _currentFat = currentFatProduct
  } else if (mixConsumptionEnabled) {
    _currentFat = currentFatMix
  } else {
    _currentFat = currentFatProduct
  }

  return {
    _currentWeight,
    _currentFat,
  }
}
