import { useStore } from "@nanostores/react"
import { Button, Col, DatePicker, Row, Tabs, Typography } from "antd"
import dayjs from "dayjs"
import {
  createRecipeMaterialsData,
  exportRecipeMaterialsToExcel,
} from "features/exportRecipeResults/exportRecipeMaterials"
import {
  createRecipeMaterialsDataInProducts,
  exportRecipeMaterialsInProductsToExcel,
} from "features/exportRecipeResults/exportRecipeMaterialsInProducts"

import { recipesCalculator } from "../../model"
import { MaterialContentLayout } from "./MaterialContentLayout"
import { RawResults } from "./RawResults"

const tomorrow = dayjs().add(1, "day")
const now = dayjs().format("DD.MM.YYYY, HH_mm")

export const RecipeCalculationResults = ({ title }: { title?: string }) => {
  const {
    basicMaterials,
    packagingMaterials,
    basicMaterialsInProducts,
    packagingMaterialsInProducts,
  } = useStore(recipesCalculator.$result)
  const assortments = useStore(recipesCalculator.$assortments)
  const inputData = useStore(recipesCalculator.$inputData)

  const exportBasicMaterials = () => {
    const title = "Основные материалы"

    const data = createRecipeMaterialsData({
      materials: basicMaterials,
      inputData,
      assortments,
      title,
    })
    exportRecipeMaterialsToExcel(data, `${title} ${now}`)
  }

  const exportBasicMaterialsInProducts = () => {
    const title = "Основные материалы (ГП)"
    const data = createRecipeMaterialsDataInProducts({
      assortments,
      inputData,
      title,
      materials: basicMaterialsInProducts,
    })
    exportRecipeMaterialsInProductsToExcel(data, `${title} ${now}`)
  }

  const exportPackagingMaterials = () => {
    const title = "Упаковочные материалы"
    const data = createRecipeMaterialsData({
      materials: packagingMaterials,
      inputData,
      assortments,
      title,
    })
    exportRecipeMaterialsToExcel(data, `${title} ${now}`)
  }

  const exportPackagingMaterialsInProducts = () => {
    const title = "Упаковочные материалы (ГП)"
    const data = createRecipeMaterialsDataInProducts({
      assortments,
      inputData,
      title,
      materials: packagingMaterialsInProducts,
    })
    exportRecipeMaterialsInProductsToExcel(data, `${title} ${now}`)
  }

  const tabItems = [
    {
      disabled: false,
      label: "Сырье",
      key: "raw",
      children: <RawResults />,
    },
    {
      disabled: basicMaterials?.length === 0,
      label: "Основные материалы",
      key: "basicMaterials",
      children: (
        <MaterialContentLayout
          exportInProducts={exportBasicMaterialsInProducts}
          exportMaterials={exportBasicMaterials}
          materialButtonLabel='ОМ'
          byMaterialData={basicMaterials}
          byProductData={basicMaterialsInProducts}
        />
      ),
    },
    {
      disabled: packagingMaterials?.length === 0,
      label: "Упаковочные материалы",
      key: "packagingMaterials",
      children: (
        <MaterialContentLayout
          exportInProducts={exportPackagingMaterialsInProducts}
          exportMaterials={exportPackagingMaterials}
          materialButtonLabel='УМ'
          byMaterialData={packagingMaterials}
          byProductData={packagingMaterialsInProducts}
        />
      ),
    },
  ].filter((el) => !el.disabled)

  return (
    <>
      <Row align='middle' gutter={[24, 24]}>
        <Col>
          <Typography.Title level={3}>{title}</Typography.Title>
        </Col>
      </Row>

      <Tabs tabBarGutter={12} type='card' items={tabItems} />

      <Row style={{ marginTop: "24px" }} gutter={8} align='middle'>
        <Col>
          <Typography.Text strong>Дата расчета:</Typography.Text>
        </Col>
        <Col>
          <DatePicker
            defaultValue={tomorrow}
            format='DD.MM.YYYY'
            showToday={false}
            placeholder='Укажите дату'
          />
        </Col>
      </Row>

      <Row style={{ marginTop: "24px" }}>
        <Button type='primary'>Сохранить расчет</Button>
      </Row>
    </>
  )
}
