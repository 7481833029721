import { SorterResult } from "antd/es/table/interface"
import { ColumnsType } from "antd/lib/table"

import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Select, Space } from "antd"
import { recipesCalculator } from "features/recipesCalculator/model"
import { NormalizationMethod, RecipeAssortment } from "features/recipesCalculator/types"

type TDataType = { normalizationMethod?: NormalizationMethod } & RecipeAssortment

export const createTableColumns = ({
  sortedInfo,
  onEdit,
  onDelete,
}: {
  sortedInfo: SorterResult<TDataType>
  onEdit: (record: RecipeAssortment) => void
  onDelete: (record: RecipeAssortment) => void
}) => {
  const cols: ColumnsType<RecipeAssortment> = [
    {
      title: "Наименование ГП",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return <>{`${record.product.name} (${record.product.fatPercentage} %)`}</>
      },
      sorter: (a, b) => a.product.name.localeCompare(b.product.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      width: 100,
    },
    {
      title: "Способ нормализации",
      dataIndex: "normalizationMethod",
      key: "normalizationMethod",
      render: (_, record) => (record.normalizationMethod === "stream" ? "В потоке" : "Объемная"),
      width: 120,
    },
    {
      title: "Масса",
      dataIndex: "currentWeight",
      key: "currentWeight",
      width: 80,
      // sorter: (a, b) => a.currentWeight - b.currentWeight,
      sortOrder: sortedInfo.columnKey === "currentWeight" ? sortedInfo.order : null,
    },
    {
      title: "Рецепт",
      dataIndex: "recipe",
      key: "recipe",
      width: 160,
      render: (_, record, index) => {
        const options = (record?.product.recipes || [])
          .slice()
          .sort((a, b) => a.num - b.num)
          .map((recipe) => ({
            label: `№${recipe.num}`,
            value: recipe.id,
          }))

        return (
          <Select
            onSelect={(v) => {
              const newAssortments = structuredClone(recipesCalculator.$assortments.get())
              newAssortments[index].selectedRecipe = record.product.recipes?.find(
                (recipe) => recipe.id === v,
              )

              recipesCalculator.$assortments.set(newAssortments)
            }}
            style={{ width: "100%" }}
            options={options}
            value={record.selectedRecipe?.id}
          />
        )
      },
    },
    {
      title: "Действие",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Space size='middle'>
            <Button onClick={() => onEdit(record)} type='primary' icon={<EditOutlined />}>
              Изменить
            </Button>
            <Button
              onClick={() => onDelete(record)}
              type='primary'
              danger
              icon={<DeleteOutlined />}
            >
              Удалить
            </Button>
          </Space>
        )
      },
    },
  ]

  return cols
}
