import React, { useMemo } from "react"
import { useLocation } from "react-router-dom"

import {
  ForkOutlined,
  PartitionOutlined,
  BuildOutlined,
  BlockOutlined,
  InboxOutlined,
  UserOutlined,
  ReadOutlined,
  ExperimentOutlined,
} from "@ant-design/icons"
import { useStore } from "@nanostores/react"
import { Button, Layout, Modal, Space, Typography } from "antd"
import { recipesCalculator } from "features/recipesCalculator/model"
import { adminModuleRoutes, clientModuleRoutes } from "shared/constants/routes"
import { MenuLayout } from "shared/layouts/MenuLayout"
import { MainHeader } from "shared/ui/MainHeader"

const { Title } = Typography

const menuAdminItems: {
  label: string
  key: React.Key
  icon?: React.ReactNode
  appendSeparator?: boolean
}[] = [
  {
    label: adminModuleRoutes["product-types"].title,
    key: adminModuleRoutes["product-types"].root,
    icon: <PartitionOutlined />,
  },
  {
    label: adminModuleRoutes.mixes.title,
    key: adminModuleRoutes.mixes.root,
    icon: <ForkOutlined />,
  },
  {
    label: adminModuleRoutes["basic-materials"].title,
    key: adminModuleRoutes["basic-materials"].root,
    icon: <BlockOutlined />,
  },
  {
    label: adminModuleRoutes["packaging-materials"].title,
    key: adminModuleRoutes["packaging-materials"].root,
    icon: <InboxOutlined />,
  },
  {
    label: adminModuleRoutes.recipes.title,
    key: adminModuleRoutes.recipes.root,
    icon: <ReadOutlined />,
  },
  {
    label: adminModuleRoutes["finished-products"].title,
    key: adminModuleRoutes["finished-products"].root,
    icon: <BuildOutlined />,
    appendSeparator: true,
  },

  {
    label: adminModuleRoutes.users.title,
    key: adminModuleRoutes.users.root,
    icon: <UserOutlined />,
  },
  {
    label: adminModuleRoutes["raw-materials"].title,
    key: adminModuleRoutes["raw-materials"].root,
    icon: <ExperimentOutlined />,
  },
]

type MainLayoutProps = {
  children?: React.ReactNode
}
export const MainLayout = ({ children }: MainLayoutProps) => {
  const changeConfirmModal = useStore(recipesCalculator.$warnModal)

  const location = useLocation()

  const moduleType = location.pathname.includes("admin") ? "admin" : "client"

  const recipeAssortments = useStore(recipesCalculator.$assortments)

  const clientKey = "recipes"

  const clientModuleConfig = {
    recipes: {
      routeKey: "recipes",
      data: recipeAssortments,
    },
  } as const

  const currentInfo = clientModuleConfig[clientKey]

  const menuClientItems: {
    label: string
    key: React.Key
    icon?: React.ReactNode
    disable?: boolean
  }[] = useMemo(() => {
    return [
      {
        label: clientModuleRoutes[currentInfo.routeKey].assortment.title,
        key: clientModuleRoutes[currentInfo.routeKey].assortment.root,
      },
      {
        label: clientModuleRoutes[currentInfo.routeKey].calculator.title,
        key: clientModuleRoutes[currentInfo.routeKey].calculator.root,
        disable: !currentInfo.data?.length,
      },
    ]
  }, [currentInfo])

  const menuItems = useMemo(() => {
    return moduleType ? (moduleType === "admin" ? menuAdminItems : menuClientItems) : []
  }, [moduleType, menuClientItems])

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MainHeader />
      <MenuLayout menuItems={menuItems}>{children}</MenuLayout>
      <Modal
        title='Подтверждение действия'
        centered
        open={!!changeConfirmModal}
        onOk={() => recipesCalculator.updateWarnModal()}
        onCancel={() => recipesCalculator.updateWarnModal()}
        footer={false}
      >
        <Space direction='vertical'>
          <Title level={5} style={{ marginBottom: 30 }}>
            {changeConfirmModal?.desc}
          </Title>
          <Space direction='horizontal'>
            <Button
              onClick={() => {
                changeConfirmModal?.confirm()
                recipesCalculator.updateWarnModal()
              }}
              type='primary'
              htmlType='submit'
            >
              Подтвердить
            </Button>
            <Button onClick={() => recipesCalculator.updateWarnModal()} htmlType='submit'>
              Отмена
            </Button>
          </Space>
        </Space>
      </Modal>
    </Layout>
  )
}
