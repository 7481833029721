import { atom } from "nanostores"

type TWarnModal =
  | {
      desc: string
      confirm: () => void
    }
  | undefined

const $warnModal = atom<TWarnModal>()

const updateWarnModal = (newWarnModal?: TWarnModal) => {
  $warnModal.set(newWarnModal)
}

export { $warnModal, updateWarnModal }
