/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-recipes-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  AllBasicMaterialsParams,
  BasicMaterialForm,
  EmptyResponse,
  ResponseBasicMaterialDto,
  ResponseListBasicMaterialDto,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить все основные материалы в алфавитном порядке
 */
export const allBasicMaterials = (params?: AllBasicMaterialsParams, signal?: AbortSignal) => {
  return customInstance<ResponseListBasicMaterialDto>({
    url: `/v1/materials/basic`,
    method: "get",
    params,
    signal,
  })
}

export const getAllBasicMaterialsQueryKey = (params?: AllBasicMaterialsParams) => {
  return [`/v1/materials/basic`, ...(params ? [params] : [])] as const
}

export const getAllBasicMaterialsQueryOptions = <
  TData = Awaited<ReturnType<typeof allBasicMaterials>>,
  TError = ErrorType<unknown>,
>(
  params?: AllBasicMaterialsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allBasicMaterials>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAllBasicMaterialsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof allBasicMaterials>>> = ({ signal }) =>
    allBasicMaterials(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof allBasicMaterials>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AllBasicMaterialsQueryResult = NonNullable<
  Awaited<ReturnType<typeof allBasicMaterials>>
>
export type AllBasicMaterialsQueryError = ErrorType<unknown>

/**
 * @summary Получить все основные материалы в алфавитном порядке
 */
export const useAllBasicMaterials = <
  TData = Awaited<ReturnType<typeof allBasicMaterials>>,
  TError = ErrorType<unknown>,
>(
  params?: AllBasicMaterialsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allBasicMaterials>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAllBasicMaterialsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать основной материал (админ)
 */
export const createBasicMaterial = (basicMaterialForm: BasicMaterialForm) => {
  return customInstance<ResponseBasicMaterialDto>({
    url: `/v1/materials/basic`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: basicMaterialForm,
  })
}

export const getCreateBasicMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBasicMaterial>>,
    TError,
    { data: BasicMaterialForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createBasicMaterial>>,
  TError,
  { data: BasicMaterialForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createBasicMaterial>>,
    { data: BasicMaterialForm }
  > = (props) => {
    const { data } = props ?? {}

    return createBasicMaterial(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateBasicMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof createBasicMaterial>>
>
export type CreateBasicMaterialMutationBody = BasicMaterialForm
export type CreateBasicMaterialMutationError = ErrorType<void>

/**
 * @summary Создать основной материал (админ)
 */
export const useCreateBasicMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createBasicMaterial>>,
    TError,
    { data: BasicMaterialForm },
    TContext
  >
}) => {
  const mutationOptions = getCreateBasicMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Создать копию (админ)
 */
export const createCopyBasicMaterial = (id: number) => {
  return customInstance<ResponseBasicMaterialDto>({
    url: `/v1/materials/basic/copy/${id}`,
    method: "post",
  })
}

export const getCreateCopyBasicMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyBasicMaterial>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCopyBasicMaterial>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCopyBasicMaterial>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return createCopyBasicMaterial(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCopyBasicMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCopyBasicMaterial>>
>

export type CreateCopyBasicMaterialMutationError = ErrorType<void>

/**
 * @summary Создать копию (админ)
 */
export const useCreateCopyBasicMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyBasicMaterial>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getCreateCopyBasicMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить основной материал по ID
 */
export const getBasicMaterial = (id: number, signal?: AbortSignal) => {
  return customInstance<ResponseBasicMaterialDto>({
    url: `/v1/materials/basic/${id}`,
    method: "get",
    signal,
  })
}

export const getGetBasicMaterialQueryKey = (id: number) => {
  return [`/v1/materials/basic/${id}`] as const
}

export const getGetBasicMaterialQueryOptions = <
  TData = Awaited<ReturnType<typeof getBasicMaterial>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBasicMaterial>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBasicMaterialQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBasicMaterial>>> = ({ signal }) =>
    getBasicMaterial(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBasicMaterial>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetBasicMaterialQueryResult = NonNullable<Awaited<ReturnType<typeof getBasicMaterial>>>
export type GetBasicMaterialQueryError = ErrorType<unknown>

/**
 * @summary Получить основной материал по ID
 */
export const useGetBasicMaterial = <
  TData = Awaited<ReturnType<typeof getBasicMaterial>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getBasicMaterial>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBasicMaterialQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Обновить основной материал (админ)
 */
export const updateBasicMaterial = (id: number, basicMaterialForm: BasicMaterialForm) => {
  return customInstance<ResponseBasicMaterialDto>({
    url: `/v1/materials/basic/${id}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: basicMaterialForm,
  })
}

export const getUpdateBasicMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBasicMaterial>>,
    TError,
    { id: number; data: BasicMaterialForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateBasicMaterial>>,
  TError,
  { id: number; data: BasicMaterialForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateBasicMaterial>>,
    { id: number; data: BasicMaterialForm }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateBasicMaterial(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateBasicMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateBasicMaterial>>
>
export type UpdateBasicMaterialMutationBody = BasicMaterialForm
export type UpdateBasicMaterialMutationError = ErrorType<void>

/**
 * @summary Обновить основной материал (админ)
 */
export const useUpdateBasicMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateBasicMaterial>>,
    TError,
    { id: number; data: BasicMaterialForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdateBasicMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить основной материал (админ)
 */
export const deleteBasicMaterial = (id: number) => {
  return customInstance<EmptyResponse>({ url: `/v1/materials/basic/${id}`, method: "delete" })
}

export const getDeleteBasicMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBasicMaterial>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteBasicMaterial>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteBasicMaterial>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return deleteBasicMaterial(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteBasicMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteBasicMaterial>>
>

export type DeleteBasicMaterialMutationError = ErrorType<void>

/**
 * @summary Удалить основной материал (админ)
 */
export const useDeleteBasicMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteBasicMaterial>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getDeleteBasicMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
