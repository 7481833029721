import { useEffect, useState } from "react"

import { Form, Input, Modal, Select } from "antd"
import {
  useCreatePackagingMaterial,
  useDeletePackagingMaterial,
  useUpdatePackagingMaterial,
} from "api/endpoints/materials-packaging/materials-packaging"
import { PackagingMaterialForm } from "api/model"
import { queryClient } from "app/queryClient"
import { measureOptions } from "shared/constants/materials/measureOptions"
import { useArticleField } from "shared/hooks/useArticleField"
import { useValidateServerField } from "shared/hooks/useValidateServeField"
import { TResponseError } from "shared/types/response"
import { ArticleField } from "shared/ui/ArticleField"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"

type FormData = Partial<PackagingMaterialForm & { id: number }>

type ModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialData?: FormData
  defaultData: FormData
  id?: number
  setInitialData: React.Dispatch<React.SetStateAction<FormData>>
}

export const PackagingMaterialsModal = ({
  isOpen,
  setIsOpen,
  id,
  initialData,
  defaultData,
  setInitialData,
}: ModalProps) => {
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [form] = Form.useForm()

  const { setFormError, handleCheckField } = useValidateServerField({
    form,
    fieldName: "name",
    error: "Материал с данным названием уже существует",
  })

  const {
    clearArticles,
    invalidArticle,
    isArticleInvalid,
    setInvalidArticle,
    setSuggestedArticle,
    suggestedArticle,
  } = useArticleField()

  const deleteMaterial = useDeletePackagingMaterial()

  const deleteMaterialClick = () => {
    deleteMaterial.mutate(
      { id: Number(id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const createMaterial = useCreatePackagingMaterial()

  const onSuccess = () => {
    clearArticles()
    setInitialData(defaultData)
    form.setFieldsValue(defaultData)
    setIsOpen(false)
    queryClient.invalidateQueries()
  }

  const createMaterialClick = (values: PackagingMaterialForm) => {
    createMaterial.mutate(
      { data: values },
      {
        onSuccess,
        onError: (e) => handleError(e as unknown as TResponseError),
      },
    )
  }

  const updateMaterial = useUpdatePackagingMaterial()

  const updateMaterialClick = (values: PackagingMaterialForm) => {
    updateMaterial.mutate(
      { id: Number(id), data: values },
      {
        onSuccess,
        onError: (e) => handleError(e as unknown as TResponseError),
      },
    )
  }

  const onFinish = (values: PackagingMaterialForm) => {
    values.name = values.name.trim()
    if (values.article === articlePrefix) {
      values.article = ""
    }

    if (id) {
      updateMaterialClick(values)
    } else {
      createMaterialClick(values)
    }
  }

  const articlePrefix = "У"

  const isFormDisabled = updateMaterial.isPending || createMaterial.isPending

  const handleError = (c: TResponseError) => {
    const errors = c?.response?.data?.info?.errors
    if (errors) {
      if (errors.some((e) => e.error === "Name must be unique")) {
        setFormError()
      }
    }
    const validArticle = c?.response?.data?.info?.validArticle
    const invalidArticle = c?.response?.data?.info?.currentArticle

    if (validArticle) {
      setSuggestedArticle(validArticle)
      setInvalidArticle(invalidArticle)
      setTimeout(() => form.validateFields(["article"]))
    }
  }

  useEffect(() => {
    form.setFieldsValue(initialData)
  }, [initialData])

  const afterClose = () => {
    clearArticles()
    setInitialData(defaultData)
  }

  return (
    <>
      <Modal
        afterClose={afterClose}
        title={`${id ? "Редактирование" : "Добавление"} упаковочного материала`}
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => {
          setSuggestedArticle(undefined)
          setIsOpen(false)
        }}
        footer={false}
      >
        <Form
          disabled={isFormDisabled}
          name='packagingMaterials'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 5 }}
          onFinish={onFinish}
        >
          <Form.Item name='name' labelCol={{ span: 8 }} label='Название' required>
            <Input
              onChange={(e) => handleCheckField(e.target.value)}
              placeholder='Название'
              required
            />
          </Form.Item>
          <ArticleField
            isInvalid={isArticleInvalid}
            suggestedValue={suggestedArticle}
            invalidValue={invalidArticle}
            prefix={articlePrefix}
            labelCol={{ span: 8 }}
            setter={form.setFieldValue}
            validate={() => form.validateFields(["article"]).then(() => clearArticles())}
          />
          <Form.Item name='measure' labelCol={{ span: 8 }} label='Единицы измерения' required>
            <Select
              placeholder='Единицы измерения'
              options={measureOptions}
              defaultValue={measureOptions[0]}
              onChange={(value) => {
                form.setFieldValue("measure", value)
              }}
            />
          </Form.Item>

          <BaseModalActions
            isDeleteVisible={Boolean(id)}
            onDelete={() => setIsOpenDeleteConfirmModal(true)}
          />
        </Form>
      </Modal>
      <ConfirmDeleteModal
        action={deleteMaterialClick}
        isOpen={isOpenDeleteConfirmModal}
        setIsOpen={setIsOpenDeleteConfirmModal}
        title='Вы уверены, что хотите удалить данный упаковочный материал?'
      />
    </>
  )
}
