import { CheckOutlined } from "@ant-design/icons"
import { Button, Modal, Space, Typography } from "antd"

const { Paragraph } = Typography

export function ConfirmSaveModal({
  isOpen,
  setIsOpen,
  action,
  okTitle = "Сохранить",
  cancelTitle = "Отмена",
  title,
  description,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  action: () => void
  okTitle?: string
  cancelTitle?: string
  title?: string
  description?: React.ReactNode
}) {
  return (
    <Modal title={title} centered visible={isOpen} onCancel={() => setIsOpen(false)} footer={null}>
      <Space direction='vertical' style={{ width: "100%" }}>
        <Paragraph style={{ fontSize: "16px" }}>{description}</Paragraph>
        <Space direction='horizontal' style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type='text' onClick={() => setIsOpen(false)} style={{ color: "#8c8c8c" }}>
            {cancelTitle}
          </Button>
          <Button
            onClick={action}
            type='primary'
            htmlType='submit'
            icon={<CheckOutlined />}
            style={{ backgroundColor: "#1890ff", borderColor: "#1890ff" }}
          >
            {okTitle}
          </Button>
        </Space>
      </Space>
    </Modal>
  )
}
