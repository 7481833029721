import { SorterResult } from "antd/es/table/interface"
import { ColumnsType } from "antd/lib/table"

import { CopyOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { RawMaterialDto } from "api/model"
import { numberToString } from "shared/utils/numberToString"

export const createTableColumns = ({
  sortedInfo,
  onEditClick,
  onCopyClick,
  isLoading,
}: {
  sortedInfo: SorterResult<RawMaterialDto>
  onEditClick: (record: RawMaterialDto) => void
  onCopyClick: (id: number) => void
  isLoading: boolean
}) => {
  const cols: ColumnsType<RawMaterialDto> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 60,
      render: (_, __, index) => index + 1,
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "МДЖ %",
      dataIndex: "fatPercentage",
      key: "fatPercentage",
      width: 150,
      sorter: (a, b) => Number(a.proteinPercentage) - Number(b.proteinPercentage),
      sortOrder: sortedInfo.columnKey === "fatPercentage" ? sortedInfo.order : null,
      render: (v) => (v ? `${numberToString(v)}%` : "-"),
    },
    {
      title: "МДБ %",
      dataIndex: "proteinPercentage",
      key: "proteinPercentage",
      width: 150,
      sorter: (a, b) => Number(a.proteinPercentage) - Number(b.proteinPercentage),
      sortOrder: sortedInfo.columnKey === "proteinPercentage" ? sortedInfo.order : null,
      render: (v) => (v ? `${numberToString(v)}%` : "-"),
    },
    {
      title: "Системное название",
      dataIndex: "systemName",
      key: "systemName",
      width: 150,
    },
    {
      title: "Действие",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Space.Compact style={{ gap: "8px" }} direction='vertical'>
            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onEditClick(record)}
              type='primary'
              icon={<EditOutlined />}
            >
              Изменить
            </Button>

            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onCopyClick(record.id)}
              icon={<CopyOutlined />}
            >
              Копировать
            </Button>
          </Space.Compact>
        )
      },
    },
  ]

  return cols
}
