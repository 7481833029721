import { SorterResult } from "antd/es/table/interface"
import { ColumnsType } from "antd/lib/table"

import { CopyOutlined, EditOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { BasicMaterialDto } from "api/model"
import { measureOptions } from "shared/constants/materials/measureOptions"

export const createTableColumns = ({
  sortedInfo,
  onEditClick,
  onCopyClick,
  isLoading,
}: {
  sortedInfo: SorterResult<BasicMaterialDto>
  onEditClick: (record: BasicMaterialDto) => void
  onCopyClick: (id: number) => void
  isLoading: boolean
}) => {
  const cols: ColumnsType<BasicMaterialDto> = [
    {
      title: "Id",
      dataIndex: "mockId",
      key: "id",
      width: 60,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      sorter: (a, b) => {
        if (a.mockId && b.mockId) {
          return a.mockId - b.mockId
        }
        return 0
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "Артикул",
      dataIndex: "article",
      key: "article",
      width: 150,
      // sorter: (a, b) => a.article - b.article,
      sortOrder: sortedInfo.columnKey === "article" ? sortedInfo.order : null,
      render: (v) => v ?? "-",
    },
    {
      title: "Единицы измерения",
      dataIndex: "measure",
      key: "measure",
      width: 150,
      render: (v) => measureOptions.find(({ value }) => v === value)?.label,
    },
    {
      title: "Действие",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Space.Compact style={{ gap: "8px" }} direction='vertical'>
            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onEditClick(record)}
              type='primary'
              icon={<EditOutlined />}
            >
              Изменить
            </Button>

            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onCopyClick(record.id)}
              icon={<CopyOutlined />}
            >
              Копировать
            </Button>
          </Space.Compact>
        )
      },
    },
  ]

  return cols
}
