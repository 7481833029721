export const transformArticle = (value: string, prefix: string) => {
  let newValue = value
  if (!newValue) return undefined
  if (!newValue.startsWith(prefix)) {
    newValue = prefix
  }

  // Allow only numbers after prefix
  newValue = prefix + newValue.slice(1).replace(/[^0-9]/g, "")

  // Limit the total input length to 7 characters (1 for "O" and 6 for the numbers)
  newValue = newValue.slice(0, 6)

  return newValue
}
