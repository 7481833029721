import { useEffect, useState } from "react"

import { Form, Input, InputNumber, Modal } from "antd"
import { useCreateMix, useDeleteMix, useUpdateMix } from "api/endpoints/mixes/mixes"
import { MixForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"

type ModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialData?: Partial<MixForm & { id: number }>
  id?: number
  clearInitialData: () => void
}

export const MixesModal = ({
  isOpen,
  setIsOpen,
  initialData,
  id,
  clearInitialData,
}: ModalProps) => {
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [form] = Form.useForm<MixForm>()

  const deleteMix = useDeleteMix()

  const deleteMixClick = () => {
    deleteMix.mutate(
      { id: Number(id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const createMix = useCreateMix()

  const createMixClick = (values: MixForm) => {
    createMix.mutate(
      { data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const updateMix = useUpdateMix()

  const updateMixClick = (values: MixForm) => {
    updateMix.mutate(
      { id: Number(id), data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const isFormDisabled = updateMix.isPending || createMix.isPending

  const articlePrefix = "С"

  const onFinish = (values: MixForm) => {
    values.name = values.name.trim()
    if (values.article === articlePrefix) {
      values.article = ""
    }

    if (id) {
      updateMixClick(values)
    } else {
      createMixClick(values)
    }
  }

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
    clearInitialData()
  }, [isOpen])

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  return (
    <>
      <Modal
        title={`${id ? "Редактирование" : "Добавление"} смеси`}
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Form
          disabled={isFormDisabled}
          name='mixes'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 5 }}
          onFinish={onFinish}
        >
          <Form.Item labelCol={{ span: 6 }} name='name' label='Название' required>
            <Input placeholder='Название' required />
          </Form.Item>
          <Form.Item labelCol={{ span: 6 }} name='fatPercentage' label='МДЖ %' required>
            <InputNumber
              style={{ width: "100%" }}
              placeholder='МДЖ %'
              required
              decimalSeparator=','
              precision={2}
              max={99.99}
              min={0}
            />
          </Form.Item>
          <Form.Item labelCol={{ span: 6 }} name='proteinPercentage' label='МДБ %'>
            <InputNumber
              style={{ width: "100%" }}
              placeholder='МДБ %'
              decimalSeparator=','
              precision={2}
              max={99.99}
            />
          </Form.Item>

          <BaseModalActions
            isDeleteVisible={Boolean(id)}
            onDelete={() => setIsOpenDeleteConfirmModal(true)}
          />
        </Form>
      </Modal>

      <ConfirmDeleteModal
        isOpen={isOpenDeleteConfirmModal}
        setIsOpen={setIsOpenDeleteConfirmModal}
        title='Подтвердите удаление смеси'
        description={
          <>
            В этом случае будут <strong>удалены все продукты и рецепты</strong>, относящиеся к
            данной смеси.
          </>
        }
        action={deleteMixClick}
      />
    </>
  )
}
