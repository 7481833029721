export function formatDateAndTime(date: number | Date | undefined) {
  if (!date) return ""
  return new Intl.DateTimeFormat("ru", {
    timeStyle: "short",
    dateStyle: "short",
  }).format(new Date(date))
}

export function formatDate(date: number | Date | undefined) {
  if (!date) return ""
  return new Intl.DateTimeFormat("ru", {
    dateStyle: "short",
  }).format(new Date(date))
  // return new Intl.DateTimeFormat("ru", {
  //   timeStyle: "short",
  //   dateStyle: "short",
  // }).format(new Date(date))
}
