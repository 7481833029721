import { modulesRoutes } from "./modulesRoutes"

const getMainModuleRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "main",
  }
}

export const mainModuleRoutes = getMainModuleRoutes(modulesRoutes.main.root)
