import React, { useEffect, useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { Checkbox, Form, Input, Modal } from "antd"
import {
  useAllProductTypes,
  useCreateProductType,
  useUpdateProductType,
  useDeleteProductType,
} from "api/endpoints/products-types/products-types"
import { ProductTypeDto, ProductTypeForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseJournal } from "shared/BaseJournal"
import { defaultSortedInfo } from "shared/constants/sort"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"
import { exportTable } from "shared/utils/exportTable"
import { selectListResultOptions } from "shared/utils/selectListResult"
import { yesOrNo } from "shared/utils/yesOrNo"

import { createColumns } from "./artifacts/createColumns"

const { Item } = Form

export const ProductsTypes = () => {
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [form] = Form.useForm()
  const id = form.getFieldValue("id")
  const [sortedInfo, setSortedInfo] = useState<SorterResult<ProductTypeDto>>(defaultSortedInfo)

  // Временно скрыто по просьбе заказчика
  // const edit = (record: ProductTypeDto) => {
  //   form.setFieldValue("id", record.id)
  //   form.setFieldValue("name", record.name)
  //   form.setFieldValue("mixConsumption", record.mixConsumption)
  //   form.setFieldValue("lowFatMilkVolume", record.lowFatMilkVolume)
  //   form.setFieldValue("cottageCheeseWhey", record.cottageCheeseWhey)
  //   form.setFieldValue("cheeseWhey", record.cheeseWhey)
  //   form.setFieldValue("buttermilk", record.buttermilk)
  //   setIsOpen(true)
  // }

  const columns = createColumns(sortedInfo)

  const { data: productsTypes, isFetching: isProductTypesFetching } = useAllProductTypes(
    {},
    {
      query: { select: selectListResultOptions },
    },
  )

  const onSuccess = () => {
    queryClient.invalidateQueries()
    setIsOpen(false)
  }

  const deleteProductTypeService = useDeleteProductType()

  const deleteProductType = () => {
    deleteProductTypeService.mutate(
      { id },
      {
        onSuccess: () => {
          onSuccess()
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const createProductTypeService = useCreateProductType()

  const createProductType = (values: ProductTypeForm) => {
    createProductTypeService.mutate(
      { data: values },
      {
        onSuccess,
      },
    )
  }

  const updateProductTypeService = useUpdateProductType()

  const updateProductType = (values: ProductTypeForm) => {
    updateProductTypeService.mutate(
      { id, data: values },
      {
        onSuccess,
      },
    )
  }

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
  }, [isOpen])

  const onFinish = (values: ProductTypeForm) => {
    values.name = values.name.trim()
    id ? updateProductType(values) : createProductType(values)
  }

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const productsTypesData = useMemo(() => {
    if (!productsTypes) return []
    const data = productsTypes.map((item, index) => ({ ...item, mockId: index + 1 }))
    return searchValue
      ? data.filter(
          (productsTypes) =>
            productsTypes.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1,
        )
      : data
  }, [productsTypes, searchValue])

  const handleChange: TableProps<ProductTypeDto>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<ProductTypeDto>)
  }

  const handleExport = () => {
    if (!productsTypes) return

    const flattenData: Record<string, string | number | undefined>[] = productsTypes?.map(
      (item, index) => {
        return {
          id: index + 1,
          Название: item.name,
          "Имеется расход на 1 т": yesOrNo(item.mixConsumption),
          "Расчет творожной сыворотки": yesOrNo(item.cottageCheeseWhey),
          "Расчет подсырной сыворотки": yesOrNo(item.cheeseWhey),
          "Расчет пахты": yesOrNo(item.buttermilk),
        }
      },
    )

    exportTable({ data: flattenData, fileName: "Типы продуктов" })
  }

  return (
    <BaseJournal
      title='Тип продукта'
      // временно скрыто по просьбе заказчика
      // onAddClick={() => setIsOpen(true)}
      onExportClick={handleExport}
      filters={<Search onChange={onChange} placeholder='Поиск по названию' />}
      data={productsTypes ?? []}
      table={{
        columns,
        dataSource: productsTypesData,
        onChange: handleChange,
        loading:
          isProductTypesFetching ||
          deleteProductTypeService.isPending ||
          updateProductTypeService.isPending ||
          createProductTypeService.isPending,
      }}
      modals={
        <>
          <Modal
            title={`${id ? "Редактирование" : "Добавление"} типа продуктов`}
            centered
            open={isOpen}
            onOk={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            footer={false}
          >
            <Form
              name='mixes'
              form={form}
              style={{ marginTop: 20 }}
              labelCol={{ span: 10 }}
              onFinish={onFinish}
            >
              <Item name='name' label='Наименование продукта' required>
                <Input placeholder='Наименование продукта' required />
              </Item>
              <Item name='mixConsumption' valuePropName='checked' label=' ' colon={false}>
                <Checkbox>Расход на 1 т (да/нет)</Checkbox>
              </Item>
              <Item name='cottageCheeseWhey' valuePropName='checked' label=' ' colon={false}>
                <Checkbox>Расчет творожной сыворотки</Checkbox>
              </Item>
              <Item name='cheeseWhey' valuePropName='checked' label=' ' colon={false}>
                <Checkbox>Расчет подсырной сыворотки</Checkbox>
              </Item>
              <Item name='buttermilk' valuePropName='checked' label=' ' colon={false}>
                <Checkbox>Расчет пахты</Checkbox>
              </Item>

              <BaseModalActions
                isDeleteVisible={Boolean(id)}
                onDelete={() => setIsOpenDeleteConfirmModal(true)}
              />
            </Form>
          </Modal>

          <ConfirmDeleteModal
            action={deleteProductType}
            isOpen={isOpenDeleteConfirmModal}
            setIsOpen={setIsOpenDeleteConfirmModal}
            title='Подтвердите удаление типа продукта'
            description={
              <>
                Вы уверены, что хотите удалить данный Тип продукта? В этом случае будут{" "}
                <strong>удалены все продукты</strong>, относящиеся к данному типу продукта.
              </>
            }
          />
        </>
      }
    />
  )
}
