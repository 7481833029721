import { RouterProvider } from "react-router-dom"

import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { queryClient } from "app/queryClient"
import { router } from "app/router"

import styles from "./App.module.scss"

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className={styles.app}>
        <RouterProvider router={router} />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
