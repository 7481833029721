import { useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { Modal, Select, TableProps } from "antd"
import {
  useAllPackagingMaterials,
  useCreateCopyPackagingMaterial,
} from "api/endpoints/materials-packaging/materials-packaging"
import { PackagingMaterialDto, PackagingMaterialForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseJournal } from "shared/BaseJournal"
import { measureLocales, measureOptions } from "shared/constants/materials/measureOptions"
import { defaultSortedInfo } from "shared/constants/sort"
import { exportTable } from "shared/utils/exportTable"
import { selectListResultOptions } from "shared/utils/selectListResult"

import { createTableColumns } from "./artifacts/createTableColumns"
import { PackagingMaterialsModal } from "./components/PackagingMaterialsModal"

const defaultInitialData = {
  measure: "KILOGRAM",
  article: "У",
  name: "",
}

export const PackagingMaterials = () => {
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [selectValues, setSelectValues] = useState<string[]>([])
  const [sortedInfo, setSortedInfo] =
    useState<SorterResult<PackagingMaterialDto>>(defaultSortedInfo)
  const [initialData, setInitialData] =
    useState<Partial<PackagingMaterialForm & { id: number }>>(defaultInitialData)

  const {
    data: PackagingMaterials,
    isFetching: isMaterialsFetching,
    queryKey: PackagingMaterialsQueryKey,
  } = useAllPackagingMaterials(
    {},
    {
      query: { select: selectListResultOptions },
    },
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const onSelect = (value: string[]) => {
    setSelectValues(value)
  }

  const materialsData = useMemo(() => {
    if (!PackagingMaterials) return []
    const data = PackagingMaterials.map((item, index) => ({ ...item, mockId: index + 1 }))
    return data
      .filter((material) => {
        const isValuesFromSelectValues =
          selectValues.length > 0 ? selectValues.includes(material.measure) : true
        return searchValue && isValuesFromSelectValues
          ? material.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
          : isValuesFromSelectValues
      })
      .sort()
  }, [PackagingMaterials, searchValue, selectValues])

  const handleChange: TableProps<PackagingMaterialDto>["onChange"] = (
    pagination,
    filters,
    sorter,
  ) => {
    setSortedInfo(sorter as SorterResult<PackagingMaterialDto>)
  }

  const handleExport = () => {
    if (!PackagingMaterials) return

    const flattenData: Record<string, string | number | undefined>[] = PackagingMaterials.map(
      (item, index) => {
        return {
          id: index + 1,
          Название: item.name,
          Артикул: item.article,
          "Единицы измерения": measureLocales[item.measure as keyof typeof measureLocales],
        }
      },
    )

    exportTable({ data: flattenData, fileName: "Упаковочные материалы" })
  }

  const onEditClick = (record: PackagingMaterialDto) => {
    setInitialData(record)
    setFormIsOpen(true)
  }

  const copyPackagingMaterial = useCreateCopyPackagingMaterial()

  const onCopyClick = async (id: number) => {
    Modal.confirm({
      title: "Вы точно хотите создать дубликат материала?",
      okText: "Да",
      okType: "primary",
      cancelText: "Нет",
      icon: null,
      onOk() {
        copyPackagingMaterial
          .mutateAsync({ id })
          .then((res) => onEditClick(res.result))
          .then(() => queryClient.invalidateQueries({ queryKey: PackagingMaterialsQueryKey }))
          .catch(console.error)
      },
    })
  }

  const columns: ColumnsType<PackagingMaterialDto> = useMemo(() => {
    return createTableColumns({
      sortedInfo,
      isLoading: isMaterialsFetching || copyPackagingMaterial.isPending,
      onCopyClick,
      onEditClick,
    })
  }, [isMaterialsFetching, sortedInfo, copyPackagingMaterial.isPending])

  return (
    <BaseJournal
      title='Упаковочные материалы (УМ)'
      onAddClick={() => setFormIsOpen(true)}
      onExportClick={handleExport}
      data={PackagingMaterials ?? []}
      table={{
        columns,
        dataSource: materialsData,
        loading: isMaterialsFetching || copyPackagingMaterial.isPending,
        onChange: handleChange,
      }}
      filters={
        <>
          <Search onChange={onChange} placeholder='Поиск по названию' />
          <Select
            mode='multiple'
            allowClear
            style={{ minWidth: 200 }}
            onChange={onSelect}
            options={measureOptions}
            placeholder='Выберите единицу измерения'
          />
        </>
      }
      modals={
        <PackagingMaterialsModal
          defaultData={defaultInitialData}
          initialData={initialData}
          isOpen={formIsOpen}
          setIsOpen={setFormIsOpen}
          setInitialData={setInitialData}
          id={initialData?.id}
        />
      }
    />
  )
}
