import { modulesRoutes } from "./modulesRoutes"

const getClientRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Client",
    recipes: {
      assortment: {
        root: `${url}/recipes/assortment`,
        title: "Ассортимент продуктов",
      },
      calculator: {
        root: `${url}/recipes/calculator`,
        title: "Расчет рецептов",
      },
    },
  }
}

export const clientModuleRoutes = getClientRoutes(modulesRoutes.client.root)
