import { useEffect, useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { SorterResult } from "antd/es/table/interface"

import { useStore } from "@nanostores/react"
import { Form, Input, Modal, Select, TableProps, notification } from "antd"
import { useCreateUser, useDeleteUser, useGetAll, useUpdateUser } from "api/endpoints/users/users"
import { UserDto, UserForm } from "api/model"
import { queryClient } from "app/queryClient"
import { Role, auth } from "features/auth/model"
import { BaseJournal } from "shared/BaseJournal"
import { defaultSortedInfo } from "shared/constants/sort"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"
import { required } from "shared/validators"

import { createColumns } from "./artifacts/createColumns"

const roleOptions = [
  {
    label: "Администратор",
    value: "admin",
  },
  {
    label: "Мастер",
    value: "master",
  },
  {
    label: "Технолог",
    value: "technologist",
  },
]

type UserFormValues = Omit<UserForm, "roles"> & { roles: Role[] }

export function Users() {
  const user = useStore(auth.$user)
  const [form] = Form.useForm<UserFormValues>()
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [loginFilter, setLoginFilter] = useState("")
  const [rolesFilter, setRolesFilter] = useState<Role[]>([])
  const [sortedInfo, setSortedInfo] = useState<SorterResult<UserDto>>(defaultSortedInfo)

  const login = form.getFieldValue("login")

  const isCurrentUserEdit = user?.login === login

  const { data: users, isFetching: isUsersFetching } = useGetAll({
    query: { select: (data) => data.result },
  })

  const usersData = useMemo(() => {
    if (!users) return []

    return users.filter((user) => {
      const loginMatches = user.login?.toUpperCase().includes(loginFilter.toUpperCase())
      const rolesMatches =
        rolesFilter.length === 0 || rolesFilter.some((role) => user.roles?.includes(role))

      return loginMatches && rolesMatches
    })
  }, [users, loginFilter, rolesFilter])

  const onLoginFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginFilter(event.target.value)
  }

  const onRolesFilterChange = (value: Role[]) => {
    setRolesFilter(value)
  }

  const handleChange: TableProps<UserDto>["onChange"] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<UserDto>)
  }

  const edit = (record: UserDto) => {
    setIsEdit(true)
    form.setFieldValue("login", record.login)
    form.setFieldValue("roles", record.roles?.split(" ") ?? [])

    setIsOpen(true)
  }

  const columns = createColumns(sortedInfo, edit)

  const userCreateService = useCreateUser()
  const userUpdateService = useUpdateUser()
  const userDeleteService = useDeleteUser()

  const onSuccess = () => {
    queryClient.invalidateQueries()
    setIsOpen(false)
  }

  const deleteUser = () => {
    userDeleteService.mutate(
      { email: login },
      {
        onSuccess: () => {
          onSuccess()
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const createUser = (values: UserForm) => {
    userCreateService.mutate(
      { data: values },
      {
        onSuccess,
      },
    )
  }

  const updateUser = (values: UserForm) => {
    userUpdateService.mutate(
      { data: values },
      {
        onSuccess,
      },
    )
  }

  const onFinish = (values: UserFormValues) => {
    const data: UserForm = { ...values, roles: values.roles.join(" ") }
    login ? updateUser(data) : createUser(data)
  }

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
    setIsEdit(false)
  }, [isOpen])

  return (
    <BaseJournal
      title='Пользователи'
      onAddClick={() => setIsOpen(true)}
      data={users ?? []}
      filters={
        <>
          <Search onChange={onLoginFilterChange} placeholder='Поиск по логину' />
          <Select
            mode='multiple'
            allowClear
            style={{ minWidth: 200 }}
            options={roleOptions}
            placeholder='Выберите роли'
            onChange={onRolesFilterChange}
            filterOption={(input, option) =>
              option?.label.toLowerCase().trim().indexOf(input.toLowerCase().trim()) !== -1
            }
          />
        </>
      }
      table={{
        loading:
          isUsersFetching ||
          userCreateService.isPending ||
          userUpdateService.isPending ||
          userDeleteService.isPending,
        columns,
        dataSource: usersData,
        onChange: handleChange,
      }}
      modals={
        <>
          <Modal
            title={`${isEdit ? "Редактирование" : "Добавление"} пользователя`}
            centered
            open={isOpen}
            onOk={() => setIsOpen(false)}
            onCancel={() => setIsOpen(false)}
            footer={false}
          >
            <Form
              name='users'
              form={form}
              style={{ marginTop: 20 }}
              labelCol={{ span: 6 }}
              onFinish={onFinish}
            >
              <Form.Item name='login' label='Логин' required rules={[required]}>
                <Input placeholder='Название' />
              </Form.Item>

              {isEdit ? (
                <Form.Item name='password' label='Новый пароль'>
                  <Input placeholder='Введите, если хотите обновить пароль' />
                </Form.Item>
              ) : (
                <Form.Item name='password' required rules={[required]} label='Пароль'>
                  <Input placeholder='Пароль' />
                </Form.Item>
              )}

              <Form.Item name='roles' label='Роли' rules={[required]} required>
                <Select
                  mode='multiple'
                  allowClear
                  style={{ minWidth: 200 }}
                  options={roleOptions}
                  placeholder='Выберите роли'
                  filterOption={(input, option) =>
                    option?.label.toLowerCase().trim().indexOf(input.toLowerCase().trim()) !== -1
                  }
                />
              </Form.Item>

              <BaseModalActions
                isDeleteVisible={isEdit}
                onDelete={() => {
                  if (isCurrentUserEdit) {
                    notification.error({
                      message: "Ошибка",
                      description: "Вы не можете удалить себя",
                    })

                    return
                  }
                  setIsOpenDeleteConfirmModal(true)
                }}
              />
            </Form>
          </Modal>

          <ConfirmDeleteModal
            action={deleteUser}
            isOpen={isOpenDeleteConfirmModal}
            setIsOpen={setIsOpenDeleteConfirmModal}
            title='Подтвердите удаление пользователя'
          />
        </>
      }
    />
  )
}
