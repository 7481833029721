import { notification } from "antd"
import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { authModel } from "features/auth"

export const AXIOS_INSTANCE = axios.create({
  baseURL: process.env.REACT_APP_API,
  withCredentials: true,
})

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    try {
      const storageToken = localStorage.getItem("token")

      const token = storageToken ? JSON.parse(storageToken) : null

      if (token) {
        config.headers.JWT = token
      }
    } catch (error) {
      console.error(`axios interceptor error: ${error}`)
    }

    return config
  },
  (error) => Promise.reject(error),
)

AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      authModel.logout()
      return (window.location.href = "/login")
    }

    if ([500, 501, 502].includes(error.response.status)) {
      notification.error({
        message: "Ошибка сервера",
      })
    }

    if (error.response.status === 403) {
      notification.error({
        message: "У вас нет доступа",
      })
    }

    if (error.response.status === 404 && !error?.config?.url?.includes("/login")) {
      notification.error({
        message: "Запрашиваемый ресурс не найден",
      })
    }

    if (error.response.status === 400) {
      notification.error({
        message: "Неверный запрос",
      })
    }

    return Promise.reject(error)
  },
)

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query")
  }

  return promise
}

export default customInstance

export type ErrorType<Error> = AxiosError<Error>
