import { ChangeEvent } from "react"

import { NamePath } from "antd/es/form/interface"
import { ColProps } from "antd/lib"

import { Form, Input } from "antd"
import { transformArticle } from "shared/utils/transformArticle"

const article = "article"

const { Item } = Form
export function ArticleField({
  prefix,
  setter,
  labelCol,
  isInvalid,
  suggestedValue,
  invalidValue,
  validate,
}: {
  prefix: string
  setter: (name: NamePath, value: unknown) => void
  labelCol?: ColProps
  isInvalid?: boolean
  suggestedValue?: string
  invalidValue?: string
  validate?: () => void
}) {
  const onArticleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setter(article, transformArticle(e.target.value, prefix))
  }

  return (
    <Item
      initialValue={prefix}
      rules={[
        () => ({
          validator(_, value) {
            if (!isInvalid) {
              return Promise.resolve()
            }

            if (value !== invalidValue) {
              return Promise.resolve()
            }

            return Promise.reject(
              new Error(
                `Артикул "${value}" уже используется. Ближайший свободный артикул: ${suggestedValue}`,
              ),
            )
          },
        }),
      ]}
      validateTrigger='onChange'
      name={article}
      labelCol={labelCol}
      label='Артикул'
    >
      <Input
        addonAfter={
          suggestedValue &&
          suggestedValue !== invalidValue &&
          isInvalid && (
            <div
              style={{ cursor: "pointer", color: "black" }}
              onClick={() => {
                setter(article, suggestedValue)
                validate?.()
              }}
            >
              Указать {suggestedValue}
            </div>
          )
        }
        onChange={onArticleChange}
        minLength={0}
        maxLength={6}
        placeholder={`${prefix}54321`}
      />
    </Item>
  )
}
