/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-recipes-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  AllPackagingMaterialsParams,
  EmptyResponse,
  PackagingMaterialForm,
  ResponseListPackagingMaterialDto,
  ResponsePackagingMaterialDto,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить все упаковочные материалы в алфавитном порядке
 */
export const allPackagingMaterials = (
  params?: AllPackagingMaterialsParams,
  signal?: AbortSignal,
) => {
  return customInstance<ResponseListPackagingMaterialDto>({
    url: `/v1/materials/packaging`,
    method: "get",
    params,
    signal,
  })
}

export const getAllPackagingMaterialsQueryKey = (params?: AllPackagingMaterialsParams) => {
  return [`/v1/materials/packaging`, ...(params ? [params] : [])] as const
}

export const getAllPackagingMaterialsQueryOptions = <
  TData = Awaited<ReturnType<typeof allPackagingMaterials>>,
  TError = ErrorType<unknown>,
>(
  params?: AllPackagingMaterialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof allPackagingMaterials>>, TError, TData>
    >
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAllPackagingMaterialsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof allPackagingMaterials>>> = ({ signal }) =>
    allPackagingMaterials(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof allPackagingMaterials>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AllPackagingMaterialsQueryResult = NonNullable<
  Awaited<ReturnType<typeof allPackagingMaterials>>
>
export type AllPackagingMaterialsQueryError = ErrorType<unknown>

/**
 * @summary Получить все упаковочные материалы в алфавитном порядке
 */
export const useAllPackagingMaterials = <
  TData = Awaited<ReturnType<typeof allPackagingMaterials>>,
  TError = ErrorType<unknown>,
>(
  params?: AllPackagingMaterialsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof allPackagingMaterials>>, TError, TData>
    >
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAllPackagingMaterialsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать упаковочный материал (админ)
 */
export const createPackagingMaterial = (packagingMaterialForm: PackagingMaterialForm) => {
  return customInstance<ResponsePackagingMaterialDto>({
    url: `/v1/materials/packaging`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: packagingMaterialForm,
  })
}

export const getCreatePackagingMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPackagingMaterial>>,
    TError,
    { data: PackagingMaterialForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPackagingMaterial>>,
  TError,
  { data: PackagingMaterialForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPackagingMaterial>>,
    { data: PackagingMaterialForm }
  > = (props) => {
    const { data } = props ?? {}

    return createPackagingMaterial(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePackagingMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPackagingMaterial>>
>
export type CreatePackagingMaterialMutationBody = PackagingMaterialForm
export type CreatePackagingMaterialMutationError = ErrorType<void>

/**
 * @summary Создать упаковочный материал (админ)
 */
export const useCreatePackagingMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPackagingMaterial>>,
    TError,
    { data: PackagingMaterialForm },
    TContext
  >
}) => {
  const mutationOptions = getCreatePackagingMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Создать копию (админ)
 */
export const createCopyPackagingMaterial = (id: number) => {
  return customInstance<ResponsePackagingMaterialDto>({
    url: `/v1/materials/packaging/copy/${id}`,
    method: "post",
  })
}

export const getCreateCopyPackagingMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyPackagingMaterial>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCopyPackagingMaterial>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCopyPackagingMaterial>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return createCopyPackagingMaterial(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCopyPackagingMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCopyPackagingMaterial>>
>

export type CreateCopyPackagingMaterialMutationError = ErrorType<void>

/**
 * @summary Создать копию (админ)
 */
export const useCreateCopyPackagingMaterial = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyPackagingMaterial>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getCreateCopyPackagingMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить упаковочный материал по ID
 */
export const getPackagingMaterial = (id: number, signal?: AbortSignal) => {
  return customInstance<ResponsePackagingMaterialDto>({
    url: `/v1/materials/packaging/${id}`,
    method: "get",
    signal,
  })
}

export const getGetPackagingMaterialQueryKey = (id: number) => {
  return [`/v1/materials/packaging/${id}`] as const
}

export const getGetPackagingMaterialQueryOptions = <
  TData = Awaited<ReturnType<typeof getPackagingMaterial>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPackagingMaterial>>, TError, TData>
    >
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPackagingMaterialQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPackagingMaterial>>> = ({ signal }) =>
    getPackagingMaterial(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPackagingMaterial>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPackagingMaterialQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPackagingMaterial>>
>
export type GetPackagingMaterialQueryError = ErrorType<unknown>

/**
 * @summary Получить упаковочный материал по ID
 */
export const useGetPackagingMaterial = <
  TData = Awaited<ReturnType<typeof getPackagingMaterial>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getPackagingMaterial>>, TError, TData>
    >
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPackagingMaterialQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Обновить упаковочный материал (админ)
 */
export const updatePackagingMaterial = (
  id: number,
  packagingMaterialForm: PackagingMaterialForm,
) => {
  return customInstance<ResponsePackagingMaterialDto>({
    url: `/v1/materials/packaging/${id}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: packagingMaterialForm,
  })
}

export const getUpdatePackagingMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePackagingMaterial>>,
    TError,
    { id: number; data: PackagingMaterialForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePackagingMaterial>>,
  TError,
  { id: number; data: PackagingMaterialForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePackagingMaterial>>,
    { id: number; data: PackagingMaterialForm }
  > = (props) => {
    const { id, data } = props ?? {}

    return updatePackagingMaterial(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePackagingMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePackagingMaterial>>
>
export type UpdatePackagingMaterialMutationBody = PackagingMaterialForm
export type UpdatePackagingMaterialMutationError = ErrorType<void>

/**
 * @summary Обновить упаковочный материал (админ)
 */
export const useUpdatePackagingMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePackagingMaterial>>,
    TError,
    { id: number; data: PackagingMaterialForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdatePackagingMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить упаковочный материал (админ)
 */
export const deletePackagingMaterial = (id: number) => {
  return customInstance<EmptyResponse>({ url: `/v1/materials/packaging/${id}`, method: "delete" })
}

export const getDeletePackagingMaterialMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePackagingMaterial>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePackagingMaterial>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePackagingMaterial>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return deletePackagingMaterial(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePackagingMaterialMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePackagingMaterial>>
>

export type DeletePackagingMaterialMutationError = ErrorType<void>

/**
 * @summary Удалить упаковочный материал (админ)
 */
export const useDeletePackagingMaterial = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePackagingMaterial>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getDeletePackagingMaterialMutationOptions(options)

  return useMutation(mutationOptions)
}
