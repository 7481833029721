/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-recipes-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  AllProductsParams,
  EmptyResponse,
  ProductForm,
  ResponseListProductDto,
  ResponseProductDto,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить все готовые продукты в алфавитном порядке
 */
export const allProducts = (params?: AllProductsParams, signal?: AbortSignal) => {
  return customInstance<ResponseListProductDto>({
    url: `/v1/products`,
    method: "get",
    params,
    signal,
  })
}

export const getAllProductsQueryKey = (params?: AllProductsParams) => {
  return [`/v1/products`, ...(params ? [params] : [])] as const
}

export const getAllProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof allProducts>>,
  TError = ErrorType<unknown>,
>(
  params?: AllProductsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allProducts>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAllProductsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof allProducts>>> = ({ signal }) =>
    allProducts(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof allProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AllProductsQueryResult = NonNullable<Awaited<ReturnType<typeof allProducts>>>
export type AllProductsQueryError = ErrorType<unknown>

/**
 * @summary Получить все готовые продукты в алфавитном порядке
 */
export const useAllProducts = <
  TData = Awaited<ReturnType<typeof allProducts>>,
  TError = ErrorType<unknown>,
>(
  params?: AllProductsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allProducts>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAllProductsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать готовый продукт (админ)
 */
export const createProduct = (productForm: ProductForm) => {
  return customInstance<ResponseProductDto>({
    url: `/v1/products`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: productForm,
  })
}

export const getCreateProductMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProduct>>,
    TError,
    { data: ProductForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProduct>>,
  TError,
  { data: ProductForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProduct>>,
    { data: ProductForm }
  > = (props) => {
    const { data } = props ?? {}

    return createProduct(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateProductMutationResult = NonNullable<Awaited<ReturnType<typeof createProduct>>>
export type CreateProductMutationBody = ProductForm
export type CreateProductMutationError = ErrorType<void>

/**
 * @summary Создать готовый продукт (админ)
 */
export const useCreateProduct = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProduct>>,
    TError,
    { data: ProductForm },
    TContext
  >
}) => {
  const mutationOptions = getCreateProductMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Создать копию (админ)
 */
export const createCopyProduct = (id: number) => {
  return customInstance<ResponseProductDto>({ url: `/v1/products/copy/${id}`, method: "post" })
}

export const getCreateCopyProductMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyProduct>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCopyProduct>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCopyProduct>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return createCopyProduct(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCopyProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCopyProduct>>
>

export type CreateCopyProductMutationError = ErrorType<void>

/**
 * @summary Создать копию (админ)
 */
export const useCreateCopyProduct = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyProduct>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getCreateCopyProductMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить готовый продукт по ID
 */
export const getProduct = (id: number, signal?: AbortSignal) => {
  return customInstance<ResponseProductDto>({ url: `/v1/products/${id}`, method: "get", signal })
}

export const getGetProductQueryKey = (id: number) => {
  return [`/v1/products/${id}`] as const
}

export const getGetProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = ({ signal }) =>
    getProduct(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetProductQueryResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>
export type GetProductQueryError = ErrorType<unknown>

/**
 * @summary Получить готовый продукт по ID
 */
export const useGetProduct = <
  TData = Awaited<ReturnType<typeof getProduct>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Обновить готовый продукт (админ)
 */
export const updateProduct = (id: number, productForm: ProductForm) => {
  return customInstance<ResponseProductDto>({
    url: `/v1/products/${id}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: productForm,
  })
}

export const getUpdateProductMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProduct>>,
    TError,
    { id: number; data: ProductForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateProduct>>,
  TError,
  { id: number; data: ProductForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateProduct>>,
    { id: number; data: ProductForm }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateProduct(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateProductMutationResult = NonNullable<Awaited<ReturnType<typeof updateProduct>>>
export type UpdateProductMutationBody = ProductForm
export type UpdateProductMutationError = ErrorType<void>

/**
 * @summary Обновить готовый продукт (админ)
 */
export const useUpdateProduct = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProduct>>,
    TError,
    { id: number; data: ProductForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdateProductMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить готовый продукт (админ)
 */
export const deleteProduct = (id: number) => {
  return customInstance<EmptyResponse>({ url: `/v1/products/${id}`, method: "delete" })
}

export const getDeleteProductMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProduct>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProduct>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProduct>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {}

    return deleteProduct(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteProductMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProduct>>>

export type DeleteProductMutationError = ErrorType<void>

/**
 * @summary Удалить готовый продукт (админ)
 */
export const useDeleteProduct = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProduct>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getDeleteProductMutationOptions(options)

  return useMutation(mutationOptions)
}
