import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { EditOutlined, CopyOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { MixDto } from "api/model"
import { numberToString } from "shared/utils/numberToString"

export const createTableColumns = ({
  sortedInfo,
  onEditClick,
  onCopyClick,
  isLoading,
}: {
  sortedInfo: SorterResult<MixDto>
  onEditClick: (record: MixDto) => void
  onCopyClick: (id: number) => void
  isLoading: boolean
}) => {
  const cols: ColumnsType<MixDto> = [
    {
      title: "Id",
      dataIndex: "mockId",
      key: "id",
      width: 60,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      sorter: (a, b) => {
        if (a.mockId && b.mockId) {
          return a.mockId - b.mockId
        }
        return 0
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
    },
    {
      title: "МДЖ %",
      dataIndex: "fatPercentage",
      key: "fatPercentage",
      width: 150,
      sorter: (a, b) => a.fatPercentage - b.fatPercentage,
      sortOrder: sortedInfo.columnKey === "fatPercentage" ? sortedInfo.order : null,
      render: (v) => `${numberToString(v)}%`,
    },
    {
      title: "МДБ %",
      dataIndex: "proteinPercentage",
      key: "proteinPercentage",
      width: 150,
      sorter: (a, b) => Number(a.proteinPercentage) - Number(b.proteinPercentage),
      sortOrder: sortedInfo.columnKey === "proteinPercentage" ? sortedInfo.order : null,
      render: (v) => (v ? `${numberToString(v)}%` : "-"),
    },
    {
      title: "Действие",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Space.Compact style={{ gap: "8px" }} direction='vertical'>
            <Button onClick={() => onEditClick(record)} type='primary' icon={<EditOutlined />}>
              Изменить
            </Button>
            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onCopyClick(record.id)}
              icon={<CopyOutlined />}
            >
              Копировать
            </Button>
          </Space.Compact>
        )
      },
    },
  ]

  return cols
}
