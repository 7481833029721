import { useState } from "react"

import { Radio, RadioChangeEvent, Row, Space, Table, Typography } from "antd"
import { RecipeBasicMaterialDto, RecipePackagingMaterialDto } from "api/model"
import { formatMaterialsData } from "features/recipesCalculator/lib/formatMaterialsData"
import {
  PackagingMaterialsInProducts,
  BasicMaterialsInProducts,
} from "features/recipesCalculator/types"

import { TabContentWrapper } from "../TabContentWrapper"
import { MaterialColumns, materialColumns } from "../artifacts/materialColumns"

const MaterialsTable = ({ data }: { data: MaterialColumns[] }) => {
  return (
    <>
      <Row>
        <Space direction='vertical'>
          <Table
            size='small'
            locale={{ emptyText: "Нет данных" }}
            bordered
            dataSource={data}
            pagination={false}
            columns={materialColumns}
          />
        </Space>
      </Row>
    </>
  )
}

const renderMaterialsTableProducts = (
  data: PackagingMaterialsInProducts | BasicMaterialsInProducts,
) => {
  if ("packagingMaterials" in data) {
    return <MaterialsTable data={formatMaterialsData(data.packagingMaterials)} />
  } else {
    return <MaterialsTable data={formatMaterialsData(data.basicMaterials)} />
  }
}

export const MaterialContentLayout = ({
  byMaterialData,
  byProductData,
  materialButtonLabel,
  exportInProducts,
  exportMaterials,
}: {
  byMaterialData: RecipeBasicMaterialDto[] | RecipePackagingMaterialDto[]
  byProductData: PackagingMaterialsInProducts[] | BasicMaterialsInProducts[]
  materialButtonLabel: string
  exportInProducts: () => void
  exportMaterials: () => void
}) => {
  const [mode, setMode] = useState<"product" | "material">("product")

  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value)
  }

  const contentByMode = {
    material: {
      content: <MaterialsTable data={formatMaterialsData(byMaterialData)} />,
      exportHandler: exportMaterials,
    },
    product: {
      content: (
        <>
          {byProductData.map((el, index) => (
            <div key={index}>
              <Typography.Title style={{ marginTop: 10 }} level={5}>
                {el.name}
              </Typography.Title>
              {renderMaterialsTableProducts(el)}
            </div>
          ))}
        </>
      ),
      exportHandler: exportInProducts,
    },
  }

  const { content, exportHandler } = contentByMode[mode]

  return (
    <TabContentWrapper exportHandler={exportHandler}>
      <Radio.Group
        buttonStyle='solid'
        onChange={handleModeChange}
        value={mode}
        style={{ marginBottom: 16 }}
      >
        <Radio.Button value='product'>Итого по ГП</Radio.Button>
        <Radio.Button value='material'>Итого по {materialButtonLabel}</Radio.Button>
      </Radio.Group>

      {content}
    </TabContentWrapper>
  )
}
