import React, { useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import type { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"
import { TableProps } from "antd/lib"

import { Modal } from "antd"
import { useAllMixes, useCreateCopyMix } from "api/endpoints/mixes/mixes"
import { MixDto, MixForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseJournal } from "shared/BaseJournal"
import { defaultSortedInfo } from "shared/constants/sort"
import { exportTable } from "shared/utils/exportTable"
import { selectListResultOptions } from "shared/utils/selectListResult"

import { createTableColumns } from "./artifacts/createTableColumns"
import { MixesModal } from "./components/MixesModal"

export const Mixes = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [initialData, setInitialData] = useState<Partial<MixForm & { id: number }>>({})

  const id = initialData?.id
  const [sortedInfo, setSortedInfo] = useState<SorterResult<MixDto>>(defaultSortedInfo)

  const {
    data: mixes,
    isFetching: isMixesFetching,
    queryKey: mixesQueryKey,
  } = useAllMixes(
    {},
    {
      query: { select: selectListResultOptions },
    },
  )

  const onEditClick = (record: MixDto) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { deleted, ...rest } = record
    setInitialData(rest)
    setIsOpen(true)
  }

  const onChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value)
  }

  const mixesData = useMemo(() => {
    if (!mixes) return []
    const data = mixes.map((item, index) => ({ ...item, mockId: index + 1 }))
    return searchValue
      ? data.filter((mix) => mix.name.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1)
      : data
  }, [mixes, searchValue])

  const handleChange: TableProps<MixDto>["onChange"] = (pagination, filters, sorter) => {
    setSortedInfo(sorter as SorterResult<MixDto>)
  }

  const handleExport = () => {
    if (!mixes) return

    const flattenData: Record<string, string | number | undefined>[] = mixes?.map((item, index) => {
      return {
        id: index + 1,
        Название: item.name,
        "МДЖ %": item.fatPercentage,
        "МДБ %": item.proteinPercentage,
        Артикул: item.article,
      }
    })

    exportTable({ data: flattenData, fileName: "Смеси" })
  }

  const isLoading = isMixesFetching

  const copyMix = useCreateCopyMix()

  const onCopyClick = async (id: number) => {
    Modal.confirm({
      title: "Вы точно хотите создать дубликат смеси?",
      okText: "Да",
      okType: "primary",
      cancelText: "Нет",
      icon: null,
      onOk() {
        copyMix
          .mutateAsync({ id })
          .then((res) => onEditClick(res.result))
          .then(() => queryClient.invalidateQueries({ queryKey: mixesQueryKey }))
          .catch(console.error)
      },
    })
  }

  const columns: ColumnsType<MixDto> = useMemo(() => {
    return createTableColumns({
      isLoading,
      onCopyClick,
      onEditClick,
      sortedInfo,
    })
  }, [mixes, sortedInfo, isLoading])

  return (
    <BaseJournal
      title='Нормализованные смеси (НС)'
      onAddClick={() => setIsOpen(true)}
      onExportClick={handleExport}
      filters={<Search onChange={onChange} placeholder='Поиск по названию' />}
      data={mixes ?? []}
      table={{
        columns,
        onChange: handleChange,
        dataSource: mixesData,
        loading: isMixesFetching || copyMix.isPending,
      }}
      modals={
        <MixesModal
          clearInitialData={() => setInitialData({})}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={id}
          initialData={initialData}
        />
      }
    />
  )
}
