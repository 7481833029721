import { useState } from "react"

export function useArticleField() {
  const [suggestedArticle, setSuggestedArticle] = useState<string | undefined>()
  const [invalidArticle, setInvalidArticle] = useState<string | undefined>(undefined)

  const clearArticles = () => {
    setSuggestedArticle(undefined)
    setInvalidArticle(undefined)
  }

  const isArticleInvalid =
    suggestedArticle && invalidArticle ? suggestedArticle !== invalidArticle : false

  return {
    suggestedArticle,
    setSuggestedArticle,
    invalidArticle,
    setInvalidArticle,
    clearArticles,
    isArticleInvalid,
  }
}
