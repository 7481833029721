import { useEffect, useState } from "react"

import { useGetRecipeNextNum } from "api/endpoints/recipes/recipes"

export const useRecommendedNum = ({
  productId,
  isCreateMode,
  isModalOpen,
  setRecommendedNum,
}: {
  productId: number
  isCreateMode: boolean
  isModalOpen: boolean
  setRecommendedNum: () => void
}) => {
  const [isNumInvalid, setIsNumInvalid] = useState(false)
  const [lastRequestedNum, setLastRequestedNum] = useState<number | undefined>()
  const [shouldReqRecommendedNum, setShouldReqRecommendedNum] = useState(isCreateMode)

  const { data: recommendedNum } = useGetRecipeNextNum(
    { productId: productId },
    { query: { select: (res) => res.result, enabled: Boolean(productId) } },
  )

  useEffect(() => {
    if (recommendedNum && isModalOpen && shouldReqRecommendedNum) {
      setRecommendedNum()
    }
  }, [recommendedNum, isModalOpen, shouldReqRecommendedNum])

  return {
    setShouldReqRecommendedNum,
    shouldReqRecommendedNum,
    recommendedNum,
    isNumInvalid,
    setIsNumInvalid,
    lastRequestedNum,
    setLastRequestedNum,
  }
}
