import { ColumnsType, SorterResult } from "antd/es/table/interface"

import { EditOutlined, CopyOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"
import { RecipeDto } from "api/model"
import { numberToString } from "shared/utils/numberToString"

export const tableColumns = ({
  sortedInfo,
  onEditClick,
  onCopyClick,
  isLoading,
}: {
  sortedInfo: SorterResult<RecipeDto>
  onEditClick: (record: RecipeDto) => void
  onCopyClick: (id: number) => void
  isLoading: boolean
}) => {
  const cols: ColumnsType<RecipeDto> = [
    {
      title: "Id",
      dataIndex: "mockId",
      key: "id",
      width: 60,
      sortOrder: sortedInfo.columnKey === "id" ? sortedInfo.order : null,
      sorter: (a, b) => {
        if (a.mockId && b.mockId) {
          return a.mockId - b.mockId
        }
        return 0
      },
    },
    {
      title: "Номер рецепта",
      dataIndex: "num",
      key: "num",
      sorter: (a, b) => a.num - b.num,
      sortOrder: sortedInfo.columnKey === "num" ? sortedInfo.order : null,
      width: 250,
    },
    // {
    //   title: "Номер рецепта",
    //   dataIndex: "name",
    //   key: "name",
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    //   sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
    //   ellipsis: true,
    //   width: 200,
    // },
    {
      title: "Наименование ГП",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => a.product.name.localeCompare(b.product.name),
      sortOrder: sortedInfo.columnKey === "product" ? sortedInfo.order : null,
      ellipsis: true,
      render: (product) => {
        if (!product) return "-"
        const { name, fatPercentage } = product
        const fat = fatPercentage ? `, ${numberToString(fatPercentage)}%` : ""
        return `${name ?? ""}${fat}`
      },
    },
    {
      title: "Активный",
      dataIndex: "active",
      key: "active",
      render: (v) => (v ? "Да" : "Нет"),
      width: 120,
    },
    {
      title: "Действие",
      key: "action",
      width: 150,
      render: (_, record) => {
        return (
          <Space.Compact style={{ gap: "8px" }} direction='vertical'>
            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onEditClick(record)}
              type='primary'
              icon={<EditOutlined />}
            >
              Изменить
            </Button>

            <Button
              style={{ width: "100%" }}
              size='small'
              loading={isLoading}
              onClick={() => onCopyClick(record.id)}
              icon={<CopyOutlined />}
            >
              Копировать
            </Button>
          </Space.Compact>
        )
      },
    },
  ]

  return cols
}
