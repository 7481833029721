import { modulesRoutes } from "./modulesRoutes"

const getAdminRoutes = (baseURL: string) => {
  const url = baseURL !== "" ? `${baseURL}` : ""
  return {
    root: `${url}`,
    title: "Админ",
    "product-types": {
      root: `${url}/product-types`,
      title: "Тип продукта",
    },
    mixes: {
      root: `${url}/mixes`,
      title: "Нормализованные смеси (НС)",
    },
    "basic-materials": {
      root: `${url}/basic-materials`,
      title: "Основные материалы (ОМ)",
    },
    "packaging-materials": {
      root: `${url}/packaging-materials`,
      title: "Упаковочные материалы (УМ)",
    },
    recipes: {
      root: `${url}/recipes`,
      title: "Рецептуры",
    },
    "finished-products": {
      root: `${url}/finished-products`,
      title: "Готовые продукты",
    },
    "raw-materials": {
      root: `${url}/raw-materials`,
      title: "Сырье",
    },

    users: {
      root: `${url}/users`,
      title: "Пользователи",
    },
  }
}

export const adminModuleRoutes = getAdminRoutes(modulesRoutes.admin.root)
