import { RecipeAssortment, Calculate, InputData } from "features/recipesCalculator/types"
import * as XLSX from "xlsx"

const roundNumber = (value?: number | null) => {
  if (!value) return 0
  return Math.round(value)
}

const makeNonLossData = (result: Calculate | undefined, inputData: InputData) => {
  return [
    ["", "Без учета потерь", ""],
    ["", "Приход", "Расход", "Остаток"],
    [
      "Сырое молоко",
      roundNumber(inputData?.rawMilkQuantity),
      roundNumber(inputData?.rawMilkQuantity) - roundNumber(result?.rawMilkNeedWeight),
      roundNumber(result?.rawMilkNeedWeight),
    ],
    ["Сливки", roundNumber(result?.creamOutputWeight), 0, roundNumber(result?.creamCurrentWeight)],
    [
      "Обрат",
      roundNumber(result?.freeFatMilkOutputWeight),
      0,
      roundNumber(result?.freeFatMilkCurrentWeight),
    ],
    [
      "Творожная сыворотка",
      roundNumber(result?.cottageCheeseWheyWeight),
      0,
      roundNumber(result?.cottageCheeseWheyWeight),
    ],
    [
      "Подсырная сыворотка",
      roundNumber(result?.cheeseWheyWeight),
      0,
      roundNumber(result?.cheeseWheyWeight),
    ],
    [
      "Пахта",
      roundNumber(result?.butterMilkWeightFromHiFatProducts),
      0,
      roundNumber(result?.butterMilkWeightFromHiFatProducts),
    ],
  ]
}

const makeWithLossData = (
  result: Calculate | undefined,
  resultLoss: Calculate | undefined,
  inputData: InputData,
) => {
  return [
    ["", "Без учета потерь", "", "", "С учетом потерь", "", ""],
    ["", "Приход", "Расход", "Остаток", "Приход", "Расход", "Остаток"],
    [
      "Сырое молоко",
      roundNumber(inputData?.rawMilkQuantity),
      roundNumber(inputData?.rawMilkQuantity) - roundNumber(result?.rawMilkNeedWeight),
      roundNumber(result?.rawMilkNeedWeight),
      roundNumber(inputData?.rawMilkQuantity),
      roundNumber(inputData?.rawMilkQuantity) - roundNumber(resultLoss?.rawMilkNeedWeight),
      roundNumber(resultLoss?.rawMilkNeedWeight),
    ],
    [
      "Сливки",
      roundNumber(result?.creamOutputWeight),
      0,
      roundNumber(result?.creamCurrentWeight),
      roundNumber(resultLoss?.creamOutputWeight),
      0,
      roundNumber(resultLoss?.creamCurrentWeight),
    ],
    [
      "Обрат",
      roundNumber(result?.freeFatMilkOutputWeight),
      0,
      roundNumber(result?.freeFatMilkCurrentWeight),
      roundNumber(resultLoss?.freeFatMilkOutputWeight),
      0,
      roundNumber(resultLoss?.freeFatMilkCurrentWeight),
    ],
    [
      "Творожная сыворотка",
      roundNumber(result?.cottageCheeseWheyWeight),
      0,
      roundNumber(result?.cottageCheeseWheyWeight),
      roundNumber(resultLoss?.cottageCheeseWheyWeight),
      0,
      roundNumber(resultLoss?.cottageCheeseWheyWeight),
    ],
    [
      "Подсырная сыворотка",
      roundNumber(result?.cheeseWheyWeight),
      0,
      roundNumber(result?.cheeseWheyWeight),
      roundNumber(resultLoss?.cheeseWheyWeight),
      0,
      roundNumber(resultLoss?.cheeseWheyWeight),
    ],
    [
      "Пахта",
      roundNumber(result?.butterMilkWeightFromHiFatProducts),
      0,
      roundNumber(result?.butterMilkWeightFromHiFatProducts),
      roundNumber(resultLoss?.butterMilkWeightFromHiFatProducts),
      0,
      roundNumber(resultLoss?.butterMilkWeightFromHiFatProducts),
    ],
  ]
}

export const createRecipeRawExportData = ({
  result,
  resultLoss,
  reversedResult,
  reversedResultLoss,
  inputData,
  assortments,
}: {
  result?: Calculate
  resultLoss?: Calculate
  reversedResult?: Calculate
  reversedResultLoss?: Calculate
  inputData: InputData
  assortments: RecipeAssortment[]
}) => {
  const nonLossData = makeNonLossData(result, inputData)
  const withLossData = makeWithLossData(result, resultLoss, inputData)
  const reversedNonLossData = makeNonLossData(reversedResult, inputData)
  const reversedWithLossData = makeWithLossData(reversedResult, reversedResultLoss, inputData)

  const data = [
    ["СЫРЬЕВОЙ БАЛАНС", ""],
    [],
    ["I. Исходные данные:"],
    ["Молоко сырое, количество, кг:", inputData?.rawMilkQuantity?.toFixed(2) || 0],
    ["Молоко сырое, МДЖ, %:", inputData?.rawMilkFat?.toFixed(2) || 0],
    ["МДЖ сепарированных сливок, %:", inputData?.creamFat?.toFixed(2) || 0],
    ["МДЖ обрата, %:", inputData?.freeFatMilkFat?.toFixed(2) || 0],
    ["МДЖ пахты, %:", inputData?.butterMilkFat?.toFixed(2) || 0],
    [],
    ["II. Ассортимент"],
    ...assortments.map((obj) => {
      return [
        `${obj.product.name} (${obj.product.fatPercentage}%):`,
        obj.currentWeight,
        `Рецепт № ${obj.selectedRecipe?.num}`,
        obj.normalizationMethod === "stream" ? "нормализация в потоке" : "объёмная нормализация",
      ]
    }),
    [],
    [`III. Сырье ${reversedResult ? "(поток - объем)" : ""}`, "", ""],
  ]

  if (resultLoss) {
    data.push(...withLossData)
  } else {
    data.push(...nonLossData)
  }

  if (reversedResult) {
    data.push([])
    data.push([`IV. Сырье (объем - поток)`, "", ""])
    if (reversedResultLoss) {
      data.push(...reversedWithLossData)
    } else {
      data.push(...reversedNonLossData)
    }
  }

  return data
}

export const exportRecipeRawToExcel = (data: (string | number)[][], fileName = "test") => {
  const ws = XLSX.utils.aoa_to_sheet(data)
  ws["!cols"] = [
    { wch: Math.max(40, 40) },
    { wch: Math.max(14, 14) },
    { wch: Math.max(14, 14) },
    { wch: Math.max(14, 14) },
    { wch: Math.max(14, 14) },
    { wch: Math.max(14, 14) },
    { wch: Math.max(14, 14) },
  ]

  ws["!merges"] = []

  // Dynamically find the row index for merging based on content
  data.forEach((row, rowIndex) => {
    if (row.includes("Без учета потерь") && row.includes("С учетом потерь")) {
      ws["!merges"]?.push(
        { s: { r: rowIndex, c: 1 }, e: { r: rowIndex, c: 3 } },
        { s: { r: rowIndex, c: 4 }, e: { r: rowIndex, c: 6 } },
      )
    }
  })

  const centerAlignment = { horizontal: "center", vertical: "center" }
  data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      if (colIndex > 0) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
        if (!ws[cellRef]) ws[cellRef] = { t: "s", v: cell }
        ws[cellRef].s = {
          alignment: centerAlignment,
        }
      }
    })
  })

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Export")
  XLSX.writeFile(wb, fileName + ".xlsx")
}
