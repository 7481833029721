import { useStore } from "@nanostores/react"
import { Row, Space, Table, Typography } from "antd"
import dayjs from "dayjs"
import {
  createRecipeRawExportData,
  exportRecipeRawToExcel,
} from "features/exportRecipeResults/exportRecipeRaw"
import { formatRawData } from "features/recipesCalculator/lib/formatRawData"
import { recipesCalculator } from "features/recipesCalculator/model"
import { ErrorCard } from "shared/ui/ErrorCard"

import { TabContentWrapper } from "../TabContentWrapper"
import { createRecipeRawTable } from "../artifacts/createRecipeRawTable"

const now = dayjs().format("DD.MM.YYYY, HH_mm")

export const RawResults = () => {
  const { recipeRaw, recipeRawLoss, reversedRecipeRaw, reversedRecipeRawLoss } = useStore(
    recipesCalculator.$result,
  )

  const inputData = useStore(recipesCalculator.$inputData)
  const errors = useStore(recipesCalculator.$errors)
  const showReversedCalculation = useStore(recipesCalculator.$showReversedCalculation)
  const rawTableData = formatRawData(recipeRaw, recipeRawLoss, inputData)
  const reversedRawTableData = formatRawData(reversedRecipeRaw, reversedRecipeRawLoss, inputData)
  const showRecipeRawLoss = useStore(recipesCalculator.$showRecipeRawLoss)
  const status = useStore(recipesCalculator.$status)
  const assortments = useStore(recipesCalculator.$assortments)

  console.log("recipeRaw", recipeRaw)

  const exportRaw = () => {
    const data = createRecipeRawExportData({
      result: recipeRaw,
      resultLoss: showRecipeRawLoss ? recipeRawLoss : undefined,
      reversedResult: showReversedCalculation ? reversedRecipeRaw : undefined,
      reversedResultLoss:
        showReversedCalculation && showRecipeRawLoss ? reversedRecipeRawLoss : undefined,
      assortments,
      inputData,
    })
    exportRecipeRawToExcel(data, `Сырье, ${now}`)
  }

  return (
    <TabContentWrapper exportHandler={exportRaw}>
      <>
        {errors.length > 0 && (
          <>
            <ErrorCard errors={errors} />
            <br />
          </>
        )}
        {status === "calculated" && !errors?.length && (
          <Row>
            <Space direction='vertical'>
              {showReversedCalculation && (
                <Typography.Title level={5}>(поток → объем)</Typography.Title>
              )}

              <Table
                size='small'
                bordered
                dataSource={rawTableData}
                pagination={false}
                columns={createRecipeRawTable(showRecipeRawLoss)}
              />
            </Space>
          </Row>
        )}
        {showReversedCalculation && (
          <Row>
            <Space direction='vertical'>
              <Typography.Title level={5}>Сырье (объем → поток)</Typography.Title>
              <Table
                size='small'
                bordered
                dataSource={reversedRawTableData}
                pagination={false}
                columns={createRecipeRawTable(showRecipeRawLoss)}
              />
            </Space>
          </Row>
        )}
      </>
    </TabContentWrapper>
  )
}
