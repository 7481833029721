import { DeleteOutlined } from "@ant-design/icons"
import { Button, Space } from "antd"

export function BaseModalActions({
  onDelete,
  isDeleteVisible,
}: {
  onDelete: () => void
  isDeleteVisible: boolean
}) {
  return (
    <Space
      direction={"horizontal"}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button type='primary' htmlType='submit'>
        Сохранить
      </Button>
      {isDeleteVisible && (
        <Button onClick={onDelete} type='text' danger icon={<DeleteOutlined />}>
          Удалить
        </Button>
      )}
    </Space>
  )
}
