export function getProductType({
  _currentFat,
  creamFat,
  freeFatMilkFat,
  rawMilkFat,
}: {
  _currentFat: number
  freeFatMilkFat: number
  rawMilkFat: number
  creamFat: number
}) {
  if (_currentFat >= creamFat) {
    return "hiFatProduct"
  } else if (_currentFat > rawMilkFat) {
    return "mediumFatProduct"
  } else if (_currentFat > freeFatMilkFat) {
    return "lowFatProduct"
  } else if (_currentFat === freeFatMilkFat) {
    return "freeFatProduct"
  } else {
    return "error"
  }
}
