import { PlusCircleOutlined } from "@ant-design/icons"
import { Space, Typography, Button, Table, TableProps } from "antd"

export const BaseJournal = <T extends object>({
  title,
  onAddClick,
  onExportClick,
  filters,
  table,
  data,
  modals,
}: {
  title: string
  onAddClick?: () => void
  onExportClick?: () => void
  filters?: React.ReactNode
  table: TableProps<T>
  data: T[]
  modals?: React.ReactNode
}) => {
  return (
    <>
      <Space direction='vertical'>
        <Space style={{ justifyContent: "space-between", width: "100%" }}>
          <Space>
            <Typography.Title level={2} style={{ marginBottom: 0 }}>
              {title}
            </Typography.Title>
            {onAddClick && (
              <Button onClick={onAddClick} type='primary' icon={<PlusCircleOutlined />}>
                Добавить
              </Button>
            )}
          </Space>

          {onExportClick && (
            <Button disabled={!data} onClick={onExportClick}>
              Экспорт в Excel
            </Button>
          )}
        </Space>

        {filters && <Space style={{ marginTop: 30 }}>{filters}</Space>}

        <Space style={{ marginTop: 30 }}>
          <Table
            pagination={false}
            locale={{ emptyText: "Нет данных" }}
            sticky
            style={{ maxWidth: 1200 }}
            rowKey='id'
            {...table}
          />
        </Space>
      </Space>

      {modals}
    </>
  )
}
