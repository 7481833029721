import { useEffect, useState } from "react"

import { Form, Input, InputNumber, Modal } from "antd"
import {
  useCreateRawMaterial,
  useDeleteRawMaterial,
  useUpdateRawMaterial,
} from "api/endpoints/materials-raw/materials-raw"
import { RawMaterialForm } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"
import { required } from "shared/validators"

type ModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialData?: Partial<RawMaterialForm & { id: number }>
  id?: number
  clearInitialData: () => void
}

export const RawMaterialsModal = ({
  isOpen,
  setIsOpen,
  id,
  initialData,
  clearInitialData,
}: ModalProps) => {
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [form] = Form.useForm()

  const deleteMaterial = useDeleteRawMaterial()

  const deleteMaterialClick = () => {
    deleteMaterial.mutate(
      { id: Number(id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const createMaterial = useCreateRawMaterial()

  const createMaterialClick = (values: RawMaterialForm) => {
    createMaterial.mutate(
      { data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const updateMaterial = useUpdateRawMaterial()

  const updateMaterialClick = (values: RawMaterialForm) => {
    updateMaterial.mutate(
      { id: Number(id), data: values },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const isFormDisabled = updateMaterial.isPending || createMaterial.isPending

  const onFinish = (values: RawMaterialForm) => {
    values.name = values.name.trim()

    if (id) {
      updateMaterialClick(values)
    } else {
      createMaterialClick(values)
    }
  }

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
    clearInitialData()
  }, [isOpen])

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  return (
    <>
      <Modal
        title={`${id ? "Редактирование" : "Добавление"} сырья`}
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => {
          setIsOpen(false)
        }}
        footer={false}
      >
        <Form
          disabled={isFormDisabled}
          initialValues={{ name: "", article: "", measure: "KILOGRAM" }}
          name='RawMaterials'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 5 }}
          onFinish={onFinish}
        >
          <Form.Item
            name='name'
            labelCol={{ span: 9 }}
            label='Название'
            required
            rules={[required]}
          >
            <Input placeholder='Название' />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 9 }}
            name='fatPercentage'
            label='МДЖ %'
            required
            rules={[required]}
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder='МДЖ %'
              decimalSeparator=','
              precision={2}
              max={99.99}
              min={0}
            />
          </Form.Item>
          <Form.Item labelCol={{ span: 9 }} name='proteinPercentage' label='МДБ %'>
            <InputNumber
              style={{ width: "100%" }}
              placeholder='МДБ %'
              decimalSeparator=','
              precision={2}
              max={99.99}
            />
          </Form.Item>

          <Form.Item
            labelCol={{ span: 9 }}
            required
            rules={[required]}
            name='systemName'
            label='Системное название'
          >
            <Input type='text' placeholder='Системное название' />
          </Form.Item>

          <BaseModalActions
            isDeleteVisible={Boolean(id)}
            onDelete={() => setIsOpenDeleteConfirmModal(true)}
          />
        </Form>
      </Modal>

      <ConfirmDeleteModal
        action={deleteMaterialClick}
        isOpen={isOpenDeleteConfirmModal}
        setIsOpen={setIsOpenDeleteConfirmModal}
        title='Вы уверены, что хотите удалить данное сырье?'
      />
    </>
  )
}
