import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Form, Select, Space, Button, Card, InputNumber, Typography } from "antd"
import { measureLocales } from "shared/constants/materials/measureOptions"
import { TMeasure } from "shared/types/measure"
import { validatePositiveNumber } from "shared/utils/validatePositiveNumber"
import { requiredEmpty } from "shared/validators"

const { List, Item } = Form

const labelCol = { span: 24 }

type TOption = { value: string | number; label: string; measure: string }

type FormListProps = {
  name: string
  title: string
  entityName: string
  options: TOption[]
  values: { id?: number; expenditure?: { amount?: number; lossPercentage?: number } }[]
  showSum?: boolean
}

export function FormList({
  name,
  title,
  entityName,
  options,
  values,
  showSum = false,
}: FormListProps) {
  const getMeasure = (index: number) => {
    const id = values?.[index]?.id
    const measure = options.find(({ value }) => id === value)?.measure ?? "KILOGRAM"
    return measureLocales[measure as TMeasure]
  }

  const amountSum = +(values || [])
    ?.reduce((acc, el) => {
      return acc + (el?.expenditure?.amount ?? 0)
    }, 0)
    .toFixed(5)

  const lossPercentageSum = +(values || [])
    ?.reduce((acc, el) => {
      return acc + (el?.expenditure?.lossPercentage ?? 0)
    }, 0)
    .toFixed(5)

  const filterOption = (input: string, option?: TOption) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const validatePositive = (_: unknown, value: number | undefined) => {
    if (!value || value >= 0) {
      return Promise.resolve()
    }
    return Promise.reject("Число должно быть положительным")
  }

  return (
    <List name={name}>
      {(fields, { add, remove }) => {
        return (
          <Item labelCol={{ span: 6 }} label={`${title}:`}>
            <Card size='small'>
              {fields.map((field, index) => (
                <Space
                  key={field.key}
                  style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr auto" }}
                  align='end'
                >
                  <Item
                    label={index === 0 ? entityName : undefined}
                    name={[field.name, "id"]}
                    labelCol={labelCol}
                    required
                    rules={[requiredEmpty]}
                  >
                    <Select
                      style={{ width: 400 }}
                      showSearch
                      fieldNames={{ label: "label", value: "value" }}
                      placeholder='Выберите значение'
                      options={options}
                      filterOption={filterOption}
                    />
                  </Item>
                  <Item
                    name={[field.name, "expenditure", "amount"]}
                    rules={[{ validator: validatePositiveNumber }]}
                    label={index === 0 ? "Расход" : undefined}
                    labelCol={labelCol}
                  >
                    <InputNumber
                      decimalSeparator=','
                      placeholder='Введите значение'
                      addonAfter={getMeasure(index)}
                    />
                  </Item>
                  <Item
                    name={[field.name, "expenditure", "lossPercentage"]}
                    label={index === 0 ? "Расход с учетом потерь" : undefined}
                    labelCol={labelCol}
                    rules={[{ required: false }, { validator: validatePositive }]}
                  >
                    <InputNumber
                      decimalSeparator=','
                      placeholder='Введите значение'
                      addonAfter={getMeasure(index)}
                    />
                  </Item>
                  <Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Item>
                </Space>
              ))}

              {showSum && (
                <Space
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 20px",
                    textAlign: "center",
                    marginTop: -10,
                    marginBottom: 12,
                  }}
                  align='end'
                >
                  <Typography.Text
                    style={{ display: "block", width: 250, textAlign: "start" }}
                    strong
                  >
                    Итого:
                  </Typography.Text>

                  <div>{amountSum}</div>
                  <div>{lossPercentageSum}</div>
                </Space>
              )}

              <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                Добавить {entityName.toLowerCase()}
              </Button>
            </Card>
          </Item>
        )
      }}
    </List>
  )
}
