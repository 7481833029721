import { InputData } from "features/recipesCalculator/types"
import { computed, map } from "nanostores"

import { $assortments } from "./assortments"

const $inputData = map<InputData>({})

const clearInputData = () => {
  $inputData.set({})
}

const onInputChange = (event: { name: keyof InputData; value: number | null }) => {
  $inputData.setKey(event.name, event.value ?? undefined)
}

const $isFreeFatMilkFatIsDisabled = computed(
  $inputData,
  ({ rawMilkQuantity, rawMilkFat, creamFat }) => !(rawMilkQuantity && rawMilkFat && creamFat),
)

const $isButterMilkFatIsDisabled = computed(
  [$inputData, $assortments],
  ({ rawMilkQuantity, rawMilkFat, creamFat }, assortments) => {
    if (rawMilkQuantity && rawMilkFat && creamFat) {
      const assortmentsFiltered = assortments.filter(
        (assortment) => assortment.product.type.buttermilk,
      )
      return assortmentsFiltered.length === 0
    }
    return true
  },
)

const $isCalculationButtonIsDisabled = computed(
  [$isFreeFatMilkFatIsDisabled, $isButterMilkFatIsDisabled, $inputData],
  (isFreeFatMilkFatIsDisabled, isButterMilkFatIsDisabled, inputData) => {
    const hasRequiredInputData =
      inputData?.rawMilkQuantity && inputData?.rawMilkFat && inputData?.creamFat

    if (!hasRequiredInputData) {
      return true
    }

    if (!isFreeFatMilkFatIsDisabled && !inputData.freeFatMilkFat) {
      return true
    }

    if (!isButterMilkFatIsDisabled && !inputData.butterMilkFat) {
      return true
    }

    return false
  },
)

export {
  $inputData,
  $isCalculationButtonIsDisabled,
  $isFreeFatMilkFatIsDisabled,
  $isButterMilkFatIsDisabled,
  clearInputData,
  onInputChange,
}
