import { useEffect, useMemo, useState } from "react"

import { Form, Input, InputNumber, Modal, Select } from "antd"
import {
  useCreateProduct,
  useDeleteProduct,
  useUpdateProduct,
} from "api/endpoints/products/products"
import { MixDto, ProductForm, ProductTypeDto, RecipeDto } from "api/model"
import { queryClient } from "app/queryClient"
import { BaseModalActions } from "shared/ui/BaseModalActions"
import { ConfirmDeleteModal } from "shared/ui/ConfirmDeleteModal"
import { required } from "shared/validators"

type ModalProps = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  initialData?: Partial<ProductForm & { id: number }>
  id?: number
  clearInitialData: () => void
  recipes?: RecipeDto[]
  productTypes?: ProductTypeDto[]
  mixes?: MixDto[]
  hideRecipeNumber?: boolean
  getCreatedProductId?: (id: number) => void
}

export const ProductModal = ({
  clearInitialData,
  isOpen,
  setIsOpen,
  id,
  initialData,
  recipes = [],
  productTypes = [],
  hideRecipeNumber = false,
  getCreatedProductId,
}: ModalProps) => {
  const [isOpenDeleteConfirmModal, setIsOpenDeleteConfirmModal] = useState(false)
  const [form] = Form.useForm()

  const createProduct = useCreateProduct()

  const onCreateProductClick = (values: ProductForm) => {
    const recipe = values.recipe && values.recipe > 0 ? values.recipe : null
    createProduct.mutate(
      { data: { ...values, recipe: recipe as unknown as number } },
      {
        onSuccess: (data) => {
          getCreatedProductId && getCreatedProductId(data.result.id)
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const updateProduct = useUpdateProduct()

  const updateProductClick = (values: ProductForm) => {
    const recipe = values.recipe && values.recipe > 0 ? values.recipe : null
    updateProduct.mutate(
      { id: Number(id), data: { ...values, recipe: recipe as unknown as number } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
        },
      },
    )
  }

  const deleteProduct = useDeleteProduct()

  const deleteProductClick = () => {
    deleteProduct.mutate(
      { id: Number(id) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries()
          setIsOpen(false)
          setIsOpenDeleteConfirmModal(false)
        },
      },
    )
  }

  const onFinish = (values: ProductForm) => {
    values.name = values.name.trim()
    id ? updateProductClick(values) : onCreateProductClick(values)
  }

  const recipesOptions = [
    { value: -1, label: "Не выбран" },
    ...(recipes
      ?.filter((el) => el?.product?.id === id)
      .map((recipe) => ({ value: recipe.id, label: `Рецепт №${recipe.num}` })) ?? []),
  ]

  const productsTypesOptions = useMemo(() => {
    if (!productTypes) return []
    return productTypes.map((productsType) => {
      return { value: productsType.id, label: productsType.name }
    })
  }, [productTypes])

  useEffect(() => {
    if (isOpen) return
    form.resetFields()
    clearInitialData()
  }, [isOpen])

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData)
    }
  }, [initialData])

  return (
    <>
      <Modal
        title={`${id ? "Редактирование" : "Добавление"} готового продукта`}
        centered
        open={isOpen}
        onOk={() => setIsOpen(false)}
        onCancel={() => setIsOpen(false)}
        footer={false}
      >
        <Form
          name='mixes'
          form={form}
          style={{ marginTop: 20 }}
          labelCol={{ span: 8 }}
          onFinish={onFinish}
        >
          <Form.Item name='name' label='Название' required>
            <Input placeholder='Название' required />
          </Form.Item>
          <Form.Item name='fatPercentage' label='МДЖ %' required>
            <InputNumber
              placeholder='МДЖ'
              required
              decimalSeparator=','
              precision={2}
              max={99.99}
              min={0}
            />
          </Form.Item>
          <Form.Item name='proteinPercentage' label='МДБ %'>
            <InputNumber placeholder='МДБ' decimalSeparator=',' precision={2} max={99.99} />
          </Form.Item>
          <Form.Item name='type' label='Тип продукта' rules={[required]}>
            <Select
              placeholder='Выберите тип продукта'
              style={{ minWidth: 200 }}
              options={productsTypesOptions}
            />
          </Form.Item>
          {!hideRecipeNumber && (
            <Form.Item name='recipe' initialValue={-1} label='Номер рецепта'>
              <Select
                placeholder='Выберите активный рецепт'
                style={{ minWidth: 200 }}
                options={recipesOptions}
              />
            </Form.Item>
          )}

          <BaseModalActions
            isDeleteVisible={Boolean(id)}
            onDelete={() => setIsOpenDeleteConfirmModal(true)}
          />
        </Form>
      </Modal>

      <ConfirmDeleteModal
        action={deleteProductClick}
        isOpen={isOpenDeleteConfirmModal}
        setIsOpen={setIsOpenDeleteConfirmModal}
        title='Подтвердите удаление продукта'
        description={
          <>
            В этом случае будут <strong>удалены все рецепты</strong>, относящееся к этому продукту
          </>
        }
      />
    </>
  )
}
