/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-recipes-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  AllProductTypesParams,
  EmptyResponse,
  ProductTypeForm,
  ResponseListProductTypeDto,
  ResponseProductTypeDto,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить все типы продуктов в алфавитном порядке
 */
export const allProductTypes = (params?: AllProductTypesParams, signal?: AbortSignal) => {
  return customInstance<ResponseListProductTypeDto>({
    url: `/v1/products/types`,
    method: "get",
    params,
    signal,
  })
}

export const getAllProductTypesQueryKey = (params?: AllProductTypesParams) => {
  return [`/v1/products/types`, ...(params ? [params] : [])] as const
}

export const getAllProductTypesQueryOptions = <
  TData = Awaited<ReturnType<typeof allProductTypes>>,
  TError = ErrorType<unknown>,
>(
  params?: AllProductTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allProductTypes>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAllProductTypesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof allProductTypes>>> = ({ signal }) =>
    allProductTypes(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof allProductTypes>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AllProductTypesQueryResult = NonNullable<Awaited<ReturnType<typeof allProductTypes>>>
export type AllProductTypesQueryError = ErrorType<unknown>

/**
 * @summary Получить все типы продуктов в алфавитном порядке
 */
export const useAllProductTypes = <
  TData = Awaited<ReturnType<typeof allProductTypes>>,
  TError = ErrorType<unknown>,
>(
  params?: AllProductTypesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allProductTypes>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAllProductTypesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать тип продукта (админ)
 */
export const createProductType = (productTypeForm: ProductTypeForm) => {
  return customInstance<ResponseProductTypeDto>({
    url: `/v1/products/types`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: productTypeForm,
  })
}

export const getCreateProductTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductType>>,
    TError,
    { data: ProductTypeForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProductType>>,
  TError,
  { data: ProductTypeForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProductType>>,
    { data: ProductTypeForm }
  > = (props) => {
    const { data } = props ?? {}

    return createProductType(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateProductTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createProductType>>
>
export type CreateProductTypeMutationBody = ProductTypeForm
export type CreateProductTypeMutationError = ErrorType<void>

/**
 * @summary Создать тип продукта (админ)
 */
export const useCreateProductType = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductType>>,
    TError,
    { data: ProductTypeForm },
    TContext
  >
}) => {
  const mutationOptions = getCreateProductTypeMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить тип продукта по ID
 */
export const getProductType = (id: number, signal?: AbortSignal) => {
  return customInstance<ResponseProductTypeDto>({
    url: `/v1/products/types/${id}`,
    method: "get",
    signal,
  })
}

export const getGetProductTypeQueryKey = (id: number) => {
  return [`/v1/products/types/${id}`] as const
}

export const getGetProductTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductType>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductType>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductTypeQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductType>>> = ({ signal }) =>
    getProductType(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductType>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetProductTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getProductType>>>
export type GetProductTypeQueryError = ErrorType<unknown>

/**
 * @summary Получить тип продукта по ID
 */
export const useGetProductType = <
  TData = Awaited<ReturnType<typeof getProductType>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductType>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductTypeQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Обновить тип продукта (админ)
 */
export const updateProductType = (id: number, productTypeForm: ProductTypeForm) => {
  return customInstance<ResponseProductTypeDto>({
    url: `/v1/products/types/${id}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: productTypeForm,
  })
}

export const getUpdateProductTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProductType>>,
    TError,
    { id: number; data: ProductTypeForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateProductType>>,
  TError,
  { id: number; data: ProductTypeForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateProductType>>,
    { id: number; data: ProductTypeForm }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateProductType(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateProductTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateProductType>>
>
export type UpdateProductTypeMutationBody = ProductTypeForm
export type UpdateProductTypeMutationError = ErrorType<void>

/**
 * @summary Обновить тип продукта (админ)
 */
export const useUpdateProductType = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProductType>>,
    TError,
    { id: number; data: ProductTypeForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdateProductTypeMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить тип продукта (админ)
 */
export const deleteProductType = (id: number) => {
  return customInstance<EmptyResponse>({ url: `/v1/products/types/${id}`, method: "delete" })
}

export const getDeleteProductTypeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProductType>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProductType>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProductType>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return deleteProductType(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteProductTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProductType>>
>

export type DeleteProductTypeMutationError = ErrorType<void>

/**
 * @summary Удалить тип продукта (админ)
 */
export const useDeleteProductType = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProductType>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getDeleteProductTypeMutationOptions(options)

  return useMutation(mutationOptions)
}
