import { useMemo, useState } from "react"

import Search from "antd/es/input/Search"
import { ColumnsType } from "antd/es/table"
import { SorterResult } from "antd/es/table/interface"

import { Select, TableProps, Modal } from "antd"
import { useAllProducts } from "api/endpoints/products/products"
import { useAllRecipes, useCreateCopyRecipe } from "api/endpoints/recipes/recipes"
import { RecipeDto, RecipeForm } from "api/model"
import { queryClient } from "app/queryClient"
import { exportRecipes } from "features/exportRecipes"
import { RecipeModal } from "pages/admin/Recipes/components/RecipeModal"
import { BaseJournal } from "shared/BaseJournal"

import { tableColumns } from "./artifacts/tableColumns"

const { confirm } = Modal

const activeOptions = [
  { value: true, label: "активен" },
  { value: false, label: "неактивен" },
]

const filterItems = (
  data: RecipeDto[],
  nameSubstring: string,
  activeCriteria: boolean | undefined,
  productCriteria?: number,
) => {
  return data.filter((item) => {
    const nameMatches = item.name?.includes(nameSubstring)
    const activeMatches = activeCriteria === undefined || item.active === activeCriteria
    const productMatches = productCriteria === undefined || item?.product?.id === productCriteria
    return nameMatches && activeMatches && productMatches
  })
}

export const Recipes = () => {
  const { data: recipesData, isFetching, queryKey: recipesQueryKey } = useAllRecipes()
  const { data: productsData } = useAllProducts()

  const productsOptions = productsData?.result.map((product) => ({
    label: `${product.name}, (${product.fatPercentage}%)`,
    value: product.id,
  }))

  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [activeFilter, setActiveFilter] = useState<true | false | undefined>()
  const [productFilter, setProductFilter] = useState<number | undefined>()
  const [sortedInfo, setSortedInfo] = useState<SorterResult<RecipeDto>>({})

  const [initialData, setInitialData] = useState<Partial<RecipeForm & { id: number }>>({})

  const onNameFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const onSelectFilter = (value: true | false | undefined) => {
    setActiveFilter(value)
  }

  const onProductFilter = (value: number) => {
    setProductFilter(value)
  }

  const recipes = useMemo(() => {
    if (!recipesData?.result) return []
    const data = recipesData.result.map((item, index) => ({ ...item, mockId: index + 1 }))
    return filterItems(data, searchValue, activeFilter, productFilter).sort()
  }, [searchValue, activeFilter, productFilter, recipesData?.result])

  const filterProductsOption = (input: string, option?: { label: string; value: number }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())

  const handleChange: TableProps<RecipeDto>["onChange"] = (_, __, sorter) => {
    setSortedInfo(sorter as SorterResult<RecipeDto>)
  }

  const editTableItem = (record: RecipeDto) => {
    setInitialData({
      id: record.id,
      name: record.name,
      num: record.num,
      basicMaterials: record.basicMaterials?.map((el) => ({
        ...el,
        id: el.basicMaterial?.id,
      })),
      packagingMaterials: record.packagingMaterials?.map((el) => ({
        ...el,
        id: el.packagingMaterial?.id,
      })),
      rawMaterials: record.rawMaterials?.map((el) => ({
        ...el,
        id: el.rawMaterial?.id,
      })),
      active: record.active,
      productId: record?.product?.id,
      mixId: record?.mix?.id,
      mixExpenditure: record.mixExpenditure,
    })
    setIsOpen(true)
  }

  const { data: productsResponse } = useAllProducts()

  const products = productsResponse?.result ?? []

  const handleExport = () => {
    exportRecipes({ products, recipes: recipesData?.result ?? [] })
  }

  const copyRecipe = useCreateCopyRecipe()

  const onCopyClick = async (id: number) => {
    confirm({
      title: "Вы точно хотите создать дубликат рецепта?",
      okText: "Да",
      okType: "primary",
      cancelText: "Нет",
      icon: null,
      onOk() {
        copyRecipe
          .mutateAsync({ id })
          .then((res) => editTableItem(res.result))
          .then(() => queryClient.invalidateQueries({ queryKey: recipesQueryKey }))
          .catch(console.error)
      },
    })
  }

  const columns: ColumnsType<RecipeDto> = useMemo(() => {
    return tableColumns({
      sortedInfo,
      onEditClick: editTableItem,
      onCopyClick,
      isLoading: isFetching || copyRecipe.isPending,
    })
  }, [sortedInfo, isFetching, copyRecipe.isPending])

  return (
    <BaseJournal
      title='Рецептуры'
      onAddClick={() => {
        setInitialData({})
        setIsOpen(true)
      }}
      onExportClick={handleExport}
      data={recipes}
      table={{
        loading: isFetching || copyRecipe.isPending,
        columns,
        dataSource: recipes,
        onChange: handleChange,
      }}
      modals={
        <RecipeModal
          clearInitialData={() => setInitialData({})}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          recipeId={initialData?.id ? String(initialData.id) : undefined}
          initialData={initialData}
        />
      }
      filters={
        <>
          <Search
            style={{ minWidth: 250 }}
            onChange={onNameFilter}
            placeholder='Поиск по номеру рецепта'
          />
          <Select
            allowClear
            style={{ minWidth: 200 }}
            onChange={onSelectFilter}
            options={activeOptions}
            placeholder='Выберите статус активности'
          />
          <Select
            showSearch
            allowClear
            style={{ minWidth: 300 }}
            onChange={onProductFilter}
            filterOption={filterProductsOption}
            options={productsOptions}
            placeholder='Выберите продукт'
          />
        </>
      }
    />
  )
}
