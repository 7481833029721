import { notification } from "antd"
import { RecipeAssortment } from "features/recipesCalculator/types"

const notificationError = (description: string) => {
  notification.error({
    message: "Ошибка расчета",
    description,
    placement: "topRight",
    type: "error",
  })
}

export const primaryValidation = <T extends RecipeAssortment>({
  assortments,
  rawMilkQuantity,
  rawMilkFat,
  creamFat,
  freeFatMilkFatOrig,
}: {
  assortments: T[]
  rawMilkQuantity: number
  rawMilkFat: number
  creamFat: number
  freeFatMilkFatOrig: number
  type?: "recipe" | "rawMaterial"
}): boolean => {
  if (assortments.length === 0) {
    notificationError("Перед расчетом необходимо заполнить ассортимент")
  }
  if (!rawMilkQuantity || !rawMilkFat || !creamFat) {
    notificationError("Недопустимые значения параметров сырого молока и сливок")
  }

  const stopCalculation = false
  assortments.forEach((assortment) => {
    if (stopCalculation) return
    let _currentFat // Жирность продукта в цикле
    const {
      product: {
        fatPercentage: currentFatProduct,
        type: { mixConsumption: mixConsumptionEnabled },
      },
    } = assortment

    const currentFatMix = assortment.selectedRecipe?.mix?.fatPercentage ?? 0

    if (mixConsumptionEnabled) {
      _currentFat = currentFatMix // 'жир смеси
    } else {
      _currentFat = currentFatProduct //'жир продукта
    }
    if (typeof freeFatMilkFatOrig === "number" && _currentFat < freeFatMilkFatOrig) {
      // stopCalculation = true
    }
  })

  if (stopCalculation) {
    notificationError("МДЖ обрата должна быть меньше или равна МДЖ смеси продукта")
  }

  return stopCalculation
}
