import { atom, computed, map } from "nanostores"

import { calculateRecipes } from "../lib/calculate"
import { RecipeResult } from "../types"
import { $assortments } from "./assortments"
import { $inputData } from "./inputData"

const initialRecipeRaw = {
  rawMilkNeedWeight: 0,
  rawMilkQuantity: 0,
  creamOutputWeight: 0,
  creamCurrentWeight: 0,
  freeFatMilkOutputWeight: 0,
  freeFatMilkCurrentWeight: 0,
  cottageCheeseWheyWeight: 0,
  cheeseWheyWeight: 0,
  butterMilkWeightFromHiFatProducts: 0,
}

const initialResult: RecipeResult = {
  packagingMaterials: [],
  basicMaterials: [],
  packagingMaterialsInProducts: [],
  basicMaterialsInProducts: [],
  recipeRaw: initialRecipeRaw,
  recipeRawLoss: initialRecipeRaw,
  reversedRecipeRaw: initialRecipeRaw,
  reversedRecipeRawLoss: initialRecipeRaw,
}

const $result = map<RecipeResult>(initialResult)
const $errors = atom<string[]>([])
const $status = atom<"initial" | "calculated">("initial")

const setErrors = (newErrors: string[]) => {
  $errors.set(newErrors)
}

const clearErrors = () => {
  $errors.set([])
}

const doCalculate = () => {
  const assortments = $assortments.get()
  const inputData = $inputData.get()

  const result = calculateRecipes(inputData, assortments)

  $status.set("calculated")

  const errors = result.recipeRaw.errors

  if (errors.length > 0) {
    setErrors([...new Set(errors)])
  } else {
    $result.set(result)
  }
}

const $showReversedCalculation = computed($assortments, (assortments) => {
  const hasStreamNormalization = assortments.some((el) => el.normalizationMethod === "stream")
  const hasVolumeNormalization = assortments.some((el) => el.normalizationMethod === "volume")

  return assortments.length > 1 && hasStreamNormalization && hasVolumeNormalization
})

const $showRecipeRawLoss = computed($assortments, (assortments) => {
  const hasLoss = assortments.some((el) =>
    Boolean(el.selectedRecipe?.mixExpenditure.lossPercentage),
  )

  return hasLoss
})

const clearCalculation = () => {
  $result.set(initialResult)
  $status.set("initial")
  $errors.set([])
}

export {
  $result,
  $errors,
  $status,
  $showReversedCalculation,
  $showRecipeRawLoss,
  doCalculate,
  clearCalculation,
  clearErrors,
}
