/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-recipes-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  EmptyResponse,
  LoginForm,
  ResponseListUserDto,
  ResponseTokenDto,
  ResponseUserDto,
  UserForm,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить всех пользователей
 */
export const getAll = (signal?: AbortSignal) => {
  return customInstance<ResponseListUserDto>({ url: `/v1/users`, method: "get", signal })
}

export const getGetAllQueryKey = () => {
  return [`/v1/users`] as const
}

export const getGetAllQueryOptions = <
  TData = Awaited<ReturnType<typeof getAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAll>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAll>>> = ({ signal }) => getAll(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAll>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAllQueryResult = NonNullable<Awaited<ReturnType<typeof getAll>>>
export type GetAllQueryError = ErrorType<unknown>

/**
 * @summary Получить всех пользователей
 */
export const useGetAll = <
  TData = Awaited<ReturnType<typeof getAll>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAll>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать нового пользователя (админ)
 */
export const createUser = (userForm: UserForm) => {
  return customInstance<ResponseUserDto>({
    url: `/v1/users/create`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: userForm,
  })
}

export const getCreateUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: UserForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUser>>,
  TError,
  { data: UserForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUser>>, { data: UserForm }> = (
    props,
  ) => {
    const { data } = props ?? {}

    return createUser(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
export type CreateUserMutationBody = UserForm
export type CreateUserMutationError = ErrorType<void>

/**
 * @summary Создать нового пользователя (админ)
 */
export const useCreateUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: UserForm },
    TContext
  >
}) => {
  const mutationOptions = getCreateUserMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить JWT токен (залогиниться), после чего надо проставить header с именем: JWT
 */
export const login = (loginForm: LoginForm) => {
  return customInstance<ResponseTokenDto>({
    url: `/v1/users/login`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: loginForm,
  })
}

export const getLoginMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof login>>,
  TError,
  { data: LoginForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof login>>, { data: LoginForm }> = (
    props,
  ) => {
    const { data } = props ?? {}

    return login(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type LoginMutationResult = NonNullable<Awaited<ReturnType<typeof login>>>
export type LoginMutationBody = LoginForm
export type LoginMutationError = ErrorType<unknown>

/**
 * @summary Получить JWT токен (залогиниться), после чего надо проставить header с именем: JWT
 */
export const useLogin = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof login>>,
    TError,
    { data: LoginForm },
    TContext
  >
}) => {
  const mutationOptions = getLoginMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Обновить пользователя (админ)
 */
export const updateUser = (userForm: UserForm) => {
  return customInstance<ResponseUserDto>({
    url: `/v1/users/update`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: userForm,
  })
}

export const getUpdateUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UserForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUser>>,
  TError,
  { data: UserForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, { data: UserForm }> = (
    props,
  ) => {
    const { data } = props ?? {}

    return updateUser(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
export type UpdateUserMutationBody = UserForm
export type UpdateUserMutationError = ErrorType<void>

/**
 * @summary Обновить пользователя (админ)
 */
export const useUpdateUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UserForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdateUserMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить пользователя (админ)
 */
export const deleteUser = (email: string) => {
  return customInstance<EmptyResponse>({ url: `/v1/users/${email}`, method: "delete" })
}

export const getDeleteUserMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { email: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUser>>,
  TError,
  { email: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, { email: string }> = (
    props,
  ) => {
    const { email } = props ?? {}

    return deleteUser(email)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>

export type DeleteUserMutationError = ErrorType<void>

/**
 * @summary Удалить пользователя (админ)
 */
export const useDeleteUser = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUser>>,
    TError,
    { email: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteUserMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить пользователя по email
 */
export const getUser = (login: string, signal?: AbortSignal) => {
  return customInstance<ResponseUserDto>({ url: `/v1/users/${login}`, method: "get", signal })
}

export const getGetUserQueryKey = (login: string) => {
  return [`/v1/users/${login}`] as const
}

export const getGetUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(
  login: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(login)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) =>
    getUser(login, signal)

  return { queryKey, queryFn, enabled: !!login, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUser>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ErrorType<unknown>

/**
 * @summary Получить пользователя по email
 */
export const useGetUser = <
  TData = Awaited<ReturnType<typeof getUser>>,
  TError = ErrorType<unknown>,
>(
  login: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserQueryOptions(login, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
