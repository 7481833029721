import {
  correctOrSeparateCreamAndFreeFatMilk,
  loopCalculation,
  setupStep2Metrics,
} from "../lib/calculation"
import { primaryValidation } from "../lib/validation"
import { CalculationConfig, RecipeAssortment } from "../types"
import { defaultDairyProcessingMetrics, defaultStep2Metrics } from "./utils/initialMetrics"

export const calculateRecipeRaw = (
  inputData: Record<string, number>,
  assortments: RecipeAssortment[],
  loopConfig: CalculationConfig[],
  consumptionField: "amount" | "lossPercentage" = "amount",
) => {
  const { rawMilkQuantity, rawMilkFat, creamFat, freeFatMilkFat: freeFatMilkFatOrig } = inputData

  const stopCalculation = primaryValidation({
    assortments,
    rawMilkQuantity,
    rawMilkFat,
    creamFat,
    freeFatMilkFatOrig,
    type: "recipe",
  })

  if (stopCalculation) {
    throw new Error("ошибка расчета")
  }

  let calculationState = {
    dairyProcessingMetrics: defaultDairyProcessingMetrics,
    step2Metrics: defaultStep2Metrics,
  }

  const errors: string[] = []

  loopConfig.forEach((config, index) => {
    calculationState = loopCalculation(
      {
        config,
        assortments,
        dairyProcessingMetrics: calculationState.dairyProcessingMetrics,
        inputData,
        step2Metrics: calculationState.step2Metrics,
        configIndex: index,
        consumptionField,
      },
      errors,
    )

    const freeFatMilkFat =
      freeFatMilkFatOrig === null || freeFatMilkFatOrig === undefined ? 0 : freeFatMilkFatOrig //МДЖ обрата, %

    calculationState.step2Metrics = setupStep2Metrics(
      calculationState.step2Metrics,
      calculationState.dairyProcessingMetrics,
    )

    calculationState.step2Metrics = correctOrSeparateCreamAndFreeFatMilk(
      calculationState.step2Metrics,
      {
        creamFat,
        freeFatMilkFat,
        rawMilkFat,
      },
    )

    calculationState.dairyProcessingMetrics = {
      ...defaultDairyProcessingMetrics,
      cottageCheeseWheyWeight: calculationState.dairyProcessingMetrics.cottageCheeseWheyWeight,
      cheeseWheyWeight: calculationState.dairyProcessingMetrics.cheeseWheyWeight,
    }
  })

  return {
    rawMilkNeedWeight: calculationState.step2Metrics.rawMilkNeedWeight,
    rawMilkQuantity: rawMilkQuantity - calculationState.step2Metrics.rawMilkNeedWeight,
    creamOutputWeight: calculationState.step2Metrics.creamOutputWeight,
    creamCurrentWeight: calculationState.step2Metrics.creamCurrentWeight,
    freeFatMilkOutputWeight: calculationState.step2Metrics.freeFatMilkOutputWeight,
    freeFatMilkCurrentWeight: calculationState.step2Metrics.freeFatMilkCurrentWeight,
    butterMilkWeightFromHiFatProducts: calculationState.step2Metrics.butterMilkWeight,
    cheeseWheyWeight: calculationState.dairyProcessingMetrics.cheeseWheyWeight,
    cottageCheeseWheyWeight: calculationState.dairyProcessingMetrics.cottageCheeseWheyWeight,
    errors,
  }
}
