import { DeleteOutlined } from "@ant-design/icons"
import { Button, Modal, Space, Typography } from "antd"

const { Paragraph } = Typography

export function ConfirmDeleteModal({
  isOpen,
  setIsOpen,
  action,
  okTitle = "Удалить",
  cancelTitle = "Отмена",
  title,
  description,
}: {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  action: () => void
  okTitle?: string
  cancelTitle?: string
  title?: string
  description?: React.ReactNode
}) {
  return (
    <Modal
      title={title}
      centered
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      footer={false}
    >
      <Space direction='vertical' style={{ width: "100%" }}>
        <Paragraph style={{ fontSize: "16px" }}>{description}</Paragraph>
        <Space direction='horizontal' style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type='primary' onClick={() => setIsOpen(false)} htmlType='submit'>
            {cancelTitle}
          </Button>
          <Button onClick={action} type='text' htmlType='submit' danger icon={<DeleteOutlined />}>
            {okTitle}
          </Button>
        </Space>
      </Space>
    </Modal>
  )
}
