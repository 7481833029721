import { Button, Form, Input, notification } from "antd"
import { getUser, useLogin } from "api/endpoints/users/users"
import { LoginForm } from "api/model"
import { ErrorType } from "api/mutator/custom-instance"
import { auth } from "features/auth/model"

type FieldType = {
  login?: string
  password?: string
}

export function AuthForm({ onLogin }: { onLogin?: () => void }) {
  const [form] = Form.useForm()
  const loginService = useLogin()

  const handleToken = (token?: string) => {
    if (token) {
      auth.setToken(token)
    }
  }

  const isObject = (input: unknown): input is Record<string, unknown> => {
    return typeof input === "object" && input !== null && !Array.isArray(input)
  }

  const handleError = (error: ErrorType<unknown>) => {
    const errorData = error?.response?.data
    if (isObject(errorData) && "info" in errorData) {
      notification.error({ message: "Неверный логин или пароль" })
      form.setFields([
        {
          name: "login",
          errors: [""],
        },
        {
          name: "password",
          errors: [""],
        },
      ])
    } else {
      notification.error({ message: "Ошибка авторизации" })
    }
  }

  const onFinish = (values: Required<LoginForm>) => {
    loginService.mutate(
      { data: values },
      {
        onSuccess: (res) => {
          handleToken(res.result.jwt)

          getUser(values.login)
            .then((res) => res.result)
            .then(auth.setUser)
            .catch((err) => console.error(err))

          onLogin?.()
        },
        onError: handleError,
      },
    )
  }

  return (
    <Form
      disabled={loginService.isPending}
      form={form}
      name='basic'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item<FieldType>
        label='Логин'
        name='login'
        rules={[{ required: true, message: "Поле обязательно для заполнения" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item<FieldType>
        label='Пароль'
        name='password'
        rules={[{ required: true, message: "Поле обязательно для заполнения" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Войти
        </Button>
      </Form.Item>
    </Form>
  )
}
