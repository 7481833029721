import { useEffect } from "react"

import { useStore } from "@nanostores/react"
import { useAllProducts } from "api/endpoints/products/products"

import { recipesCalculator } from "../model"
import { RecipeAssortment } from "../types"

/**
 * @description Синхронизация local storage данных ассортимента с актуальными продуктами
 */
export function useSyncAssortments() {
  const { data: products } = useAllProducts(
    {},
    {
      query: { select: (res) => res.result ?? [] },
    },
  )

  const assortments = useStore(recipesCalculator.$assortments)

  useEffect(() => {
    const syncAssortmentsWithActualProducts = () => {
      if (!products) return
      const assortmentsNew: RecipeAssortment[] = []
      products.forEach((product) => {
        assortments.forEach((assortment) => {
          if (product.id === assortment.product.id) {
            const foundRecipe = product.recipes?.find(
              (recipe) => recipe.id === assortment.selectedRecipe?.id,
            )

            assortmentsNew.push({
              ...assortment,
              product,
              selectedRecipe: foundRecipe,
            })
          }
        })
      })
      recipesCalculator.setAssortments(assortmentsNew)
    }

    syncAssortmentsWithActualProducts()
  }, [products])
}
