import { Navigate, Outlet } from "react-router-dom"

import { useStore } from "@nanostores/react"
import { auth } from "features/auth/model"

export function RequireAuthRoute() {
  const isLoggedIn = useStore(auth.$isLoggedIn)

  if (!isLoggedIn) {
    return <Navigate to='/login' />
  }

  return <Outlet />
}
