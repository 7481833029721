/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * alfa-l-service-recipes-back API
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query"
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

import type {
  AllRecipesParams,
  ByProductParams,
  EmptyResponse,
  GetRecipeNextNumParams,
  RecipeForm,
  ResponseInteger,
  ResponseListRecipeDto,
  ResponseRecipeDto,
} from "../../model"
import { customInstance } from "../../mutator/custom-instance"
import type { ErrorType } from "../../mutator/custom-instance"

/**
 * @summary Получить все рецепты в алфавитном порядке
 */
export const allRecipes = (params?: AllRecipesParams, signal?: AbortSignal) => {
  return customInstance<ResponseListRecipeDto>({
    url: `/v1/recipes`,
    method: "get",
    params,
    signal,
  })
}

export const getAllRecipesQueryKey = (params?: AllRecipesParams) => {
  return [`/v1/recipes`, ...(params ? [params] : [])] as const
}

export const getAllRecipesQueryOptions = <
  TData = Awaited<ReturnType<typeof allRecipes>>,
  TError = ErrorType<unknown>,
>(
  params?: AllRecipesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allRecipes>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAllRecipesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof allRecipes>>> = ({ signal }) =>
    allRecipes(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof allRecipes>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AllRecipesQueryResult = NonNullable<Awaited<ReturnType<typeof allRecipes>>>
export type AllRecipesQueryError = ErrorType<unknown>

/**
 * @summary Получить все рецепты в алфавитном порядке
 */
export const useAllRecipes = <
  TData = Awaited<ReturnType<typeof allRecipes>>,
  TError = ErrorType<unknown>,
>(
  params?: AllRecipesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof allRecipes>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAllRecipesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать рецепт (админ)
 */
export const createRecipe = (recipeForm: RecipeForm) => {
  return customInstance<ResponseRecipeDto>({
    url: `/v1/recipes`,
    method: "post",
    headers: { "Content-Type": "application/json" },
    data: recipeForm,
  })
}

export const getCreateRecipeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRecipe>>,
    TError,
    { data: RecipeForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createRecipe>>,
  TError,
  { data: RecipeForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createRecipe>>,
    { data: RecipeForm }
  > = (props) => {
    const { data } = props ?? {}

    return createRecipe(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateRecipeMutationResult = NonNullable<Awaited<ReturnType<typeof createRecipe>>>
export type CreateRecipeMutationBody = RecipeForm
export type CreateRecipeMutationError = ErrorType<void>

/**
 * @summary Создать рецепт (админ)
 */
export const useCreateRecipe = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createRecipe>>,
    TError,
    { data: RecipeForm },
    TContext
  >
}) => {
  const mutationOptions = getCreateRecipeMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить все рецепты в алфавитном порядке для определенного товара
 */
export const byProduct = (params?: ByProductParams, signal?: AbortSignal) => {
  return customInstance<ResponseListRecipeDto>({
    url: `/v1/recipes/byProduct`,
    method: "get",
    params,
    signal,
  })
}

export const getByProductQueryKey = (params?: ByProductParams) => {
  return [`/v1/recipes/byProduct`, ...(params ? [params] : [])] as const
}

export const getByProductQueryOptions = <
  TData = Awaited<ReturnType<typeof byProduct>>,
  TError = ErrorType<unknown>,
>(
  params?: ByProductParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof byProduct>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getByProductQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof byProduct>>> = ({ signal }) =>
    byProduct(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof byProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ByProductQueryResult = NonNullable<Awaited<ReturnType<typeof byProduct>>>
export type ByProductQueryError = ErrorType<unknown>

/**
 * @summary Получить все рецепты в алфавитном порядке для определенного товара
 */
export const useByProduct = <
  TData = Awaited<ReturnType<typeof byProduct>>,
  TError = ErrorType<unknown>,
>(
  params?: ByProductParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof byProduct>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getByProductQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Создать копию рецепта (админ)
 */
export const createCopyRecipe = (id: number) => {
  return customInstance<ResponseRecipeDto>({ url: `/v1/recipes/copy/${id}`, method: "post" })
}

export const getCreateCopyRecipeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyRecipe>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCopyRecipe>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCopyRecipe>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {}

    return createCopyRecipe(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCopyRecipeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCopyRecipe>>
>

export type CreateCopyRecipeMutationError = ErrorType<void>

/**
 * @summary Создать копию рецепта (админ)
 */
export const useCreateCopyRecipe = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCopyRecipe>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getCreateCopyRecipeMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Получить следующий номер рецепта
 */
export const getRecipeNextNum = (params?: GetRecipeNextNumParams, signal?: AbortSignal) => {
  return customInstance<ResponseInteger>({
    url: `/v1/recipes/nextNum`,
    method: "get",
    params,
    signal,
  })
}

export const getGetRecipeNextNumQueryKey = (params?: GetRecipeNextNumParams) => {
  return [`/v1/recipes/nextNum`, ...(params ? [params] : [])] as const
}

export const getGetRecipeNextNumQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecipeNextNum>>,
  TError = ErrorType<unknown>,
>(
  params?: GetRecipeNextNumParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecipeNextNum>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRecipeNextNumQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecipeNextNum>>> = ({ signal }) =>
    getRecipeNextNum(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRecipeNextNum>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRecipeNextNumQueryResult = NonNullable<Awaited<ReturnType<typeof getRecipeNextNum>>>
export type GetRecipeNextNumQueryError = ErrorType<unknown>

/**
 * @summary Получить следующий номер рецепта
 */
export const useGetRecipeNextNum = <
  TData = Awaited<ReturnType<typeof getRecipeNextNum>>,
  TError = ErrorType<unknown>,
>(
  params?: GetRecipeNextNumParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecipeNextNum>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRecipeNextNumQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Получить рецепт по ID
 */
export const getRecipe = (id: number, signal?: AbortSignal) => {
  return customInstance<ResponseRecipeDto>({ url: `/v1/recipes/${id}`, method: "get", signal })
}

export const getGetRecipeQueryKey = (id: number) => {
  return [`/v1/recipes/${id}`] as const
}

export const getGetRecipeQueryOptions = <
  TData = Awaited<ReturnType<typeof getRecipe>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecipe>>, TError, TData>>
  },
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRecipeQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecipe>>> = ({ signal }) =>
    getRecipe(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRecipe>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRecipeQueryResult = NonNullable<Awaited<ReturnType<typeof getRecipe>>>
export type GetRecipeQueryError = ErrorType<unknown>

/**
 * @summary Получить рецепт по ID
 */
export const useGetRecipe = <
  TData = Awaited<ReturnType<typeof getRecipe>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecipe>>, TError, TData>>
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRecipeQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Обновить рецепт (админ)
 */
export const updateRecipe = (id: number, recipeForm: RecipeForm) => {
  return customInstance<ResponseRecipeDto>({
    url: `/v1/recipes/${id}`,
    method: "put",
    headers: { "Content-Type": "application/json" },
    data: recipeForm,
  })
}

export const getUpdateRecipeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRecipe>>,
    TError,
    { id: number; data: RecipeForm },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRecipe>>,
  TError,
  { id: number; data: RecipeForm },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRecipe>>,
    { id: number; data: RecipeForm }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateRecipe(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateRecipeMutationResult = NonNullable<Awaited<ReturnType<typeof updateRecipe>>>
export type UpdateRecipeMutationBody = RecipeForm
export type UpdateRecipeMutationError = ErrorType<void>

/**
 * @summary Обновить рецепт (админ)
 */
export const useUpdateRecipe = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRecipe>>,
    TError,
    { id: number; data: RecipeForm },
    TContext
  >
}) => {
  const mutationOptions = getUpdateRecipeMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * @summary Удалить рецепт (админ)
 */
export const deleteRecipe = (id: number) => {
  return customInstance<EmptyResponse>({ url: `/v1/recipes/${id}`, method: "delete" })
}

export const getDeleteRecipeMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRecipe>>,
    TError,
    { id: number },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteRecipe>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRecipe>>, { id: number }> = (
    props,
  ) => {
    const { id } = props ?? {}

    return deleteRecipe(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteRecipeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRecipe>>>

export type DeleteRecipeMutationError = ErrorType<void>

/**
 * @summary Удалить рецепт (админ)
 */
export const useDeleteRecipe = <TError = ErrorType<void>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteRecipe>>,
    TError,
    { id: number },
    TContext
  >
}) => {
  const mutationOptions = getDeleteRecipeMutationOptions(options)

  return useMutation(mutationOptions)
}
