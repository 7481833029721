import { ColumnsType } from "antd/es/table"

import { Measure } from "api/model"
import { measureLocales } from "shared/constants/materials/measureOptions"

export type MaterialColumns = { name: string; consumption: number; loss: number; measure: Measure }

const baseColumnProps = {
  align: "center" as const,
  width: 200,
}

export const materialColumns: ColumnsType<MaterialColumns> = [
  {
    title: "Наименование",
    key: "name",
    dataIndex: "name",
    ...baseColumnProps,
    align: "left",
  },
  {
    title: "Расход без учета потерь",
    key: "consumption",
    dataIndex: "consumption",
    render: (value, record) => `${value} ${measureLocales[record.measure]}`,
    ...baseColumnProps,
  },
  {
    title: "Расход с учетом потерь",
    key: "loss",
    dataIndex: "loss",
    render: (value, record) => (!value ? "-" : `${value} ${measureLocales[record.measure]}`),
    ...baseColumnProps,
  },
]
