import { persistentAtom } from "@nanostores/persistent"

import { RecipeAssortment } from "../types"

const persistentKey = "recipes-calculator"

const persistentOptions = {
  encode: JSON.stringify,
  decode: JSON.parse,
}

const $assortments = persistentAtom<RecipeAssortment[]>(
  `${persistentKey}:assortments`,
  [],
  persistentOptions,
)

const setAssortments = (newAssortments: RecipeAssortment[]) => {
  $assortments.set(newAssortments)
}

const deleteAssortment = (index: number) => {
  $assortments.set($assortments.get().filter((_, i) => i !== index))
}

const clearAssortments = () => {
  $assortments.set([])
}

export { $assortments, setAssortments, deleteAssortment, clearAssortments }
