import { Navigate, createBrowserRouter } from "react-router-dom"

import { LoginPage } from "pages/Login"
import { BasicMaterials } from "pages/admin/BasicMaterials/BasicMaterials"
import { Mixes } from "pages/admin/Mixes/Mixes"
import { PackagingMaterials } from "pages/admin/PackagingMaterials/PackagingMaterials"
import { Products } from "pages/admin/Products"
import { ProductsTypes } from "pages/admin/ProductsTypes"
import { RawMaterials } from "pages/admin/RawMaterials/RawMaterials"
import { Recipes } from "pages/admin/Recipes"
import { Users } from "pages/admin/Users"
import { RecipesAssortment } from "pages/client/RecipesAssortment"
import { RecipesCalculator } from "pages/client/RecipesCalculator"
import { adminModuleRoutes, clientModuleRoutes } from "shared/constants/routes"
import { modulesRoutes } from "shared/constants/routes/modulesRoutes"
import { MainLayout } from "shared/layouts/MainLayout"

import { RequireAdminRoute } from "./RequireAdminRoute"
import { RequireAuthRoute } from "./RequireAuthRoute"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={clientModuleRoutes.recipes.assortment.root} replace={false} />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    element: (
      <MainLayout>
        <RequireAuthRoute />
      </MainLayout>
    ),
    children: [
      {
        path: clientModuleRoutes.recipes.assortment.root,
        element: <RecipesAssortment />,
      },
      {
        path: clientModuleRoutes.recipes.calculator.root,
        element: <RecipesCalculator />,
      },
      {
        element: <RequireAdminRoute />,
        children: [
          {
            path: adminModuleRoutes["finished-products"].root,
            element: <Products />,
          },
          {
            path: adminModuleRoutes["product-types"].root,
            element: <ProductsTypes />,
          },
          {
            path: adminModuleRoutes.mixes.root,
            element: <Mixes />,
          },
          {
            path: adminModuleRoutes["basic-materials"].root,
            element: <BasicMaterials />,
          },
          {
            path: adminModuleRoutes["packaging-materials"].root,
            element: <PackagingMaterials />,
          },
          {
            path: adminModuleRoutes["raw-materials"].root,
            element: <RawMaterials />,
          },
          {
            path: adminModuleRoutes.recipes.root,
            element: <Recipes />,
          },
          {
            path: `${adminModuleRoutes.root}/*`,
            element: <Navigate to={adminModuleRoutes["finished-products"].root} replace={false} />,
          },
          {
            path: `${adminModuleRoutes.users.root}/*`,
            element: <Users />,
          },
        ],
      },
      {
        path: `${modulesRoutes.root}/*`,
        // element: <Navigate to={clientModuleRoutes.recipes.assortment.root} replace={false} />,
        element: <></>,
      },
    ],
  },
])
