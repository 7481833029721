export const defaultSortedInfo = {
  column: {
    title: "Id",
    dataIndex: "id",
    key: "id",
    width: 60,
    sortOrder: "ascend",
  },
  order: "descend",
  field: "id",
  columnKey: "id",
} as const
