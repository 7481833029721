import { validateAndRoundNumberForResult } from "shared/utils/validateAndRoundNumber"

import { InputData, RecipeResultRaw } from "../types"

const calculateBalance = (income: InputData["rawMilkQuantity"], consumption: number) => {
  const validatedIncome = validateAndRoundNumberForResult(income)
  const validatedConsumption = validateAndRoundNumberForResult(consumption)

  if (validatedIncome === "ошибка" || validatedConsumption === "ошибка") {
    return "ошибка"
  }

  return validatedIncome - validatedConsumption
}

export const formatRawData = (
  recipeRaw: RecipeResultRaw,
  recipeRawLoss: RecipeResultRaw,
  inputData: InputData,
) => {
  return [
    {
      material: "Сырое молоко",
      income: validateAndRoundNumberForResult(inputData.rawMilkQuantity), // Приход
      balance: calculateBalance(inputData.rawMilkQuantity, recipeRaw.rawMilkNeedWeight), // Остаток
      consumption: validateAndRoundNumberForResult(recipeRaw.rawMilkNeedWeight), // Расход
      incomeLoss: validateAndRoundNumberForResult(inputData.rawMilkQuantity), // Приход с учетом потерь
      balanceLoss: calculateBalance(inputData.rawMilkQuantity, recipeRawLoss.rawMilkNeedWeight), // Остаток с учетом потерь
      consumptionLoss: validateAndRoundNumberForResult(recipeRawLoss.rawMilkNeedWeight), // Расход с учетом потерь
    },
    {
      material: "Сливки",
      income: validateAndRoundNumberForResult(recipeRaw.creamOutputWeight),
      balance: validateAndRoundNumberForResult(recipeRaw.creamCurrentWeight),
      consumption: 0,
      incomeLoss: validateAndRoundNumberForResult(recipeRawLoss.creamOutputWeight),
      balanceLoss: validateAndRoundNumberForResult(recipeRawLoss.creamCurrentWeight),
      consumptionLoss: 0,
    },
    {
      material: "Обрат",
      income: validateAndRoundNumberForResult(recipeRaw.freeFatMilkOutputWeight),
      balance: validateAndRoundNumberForResult(recipeRaw.freeFatMilkCurrentWeight),
      consumption: 0,
      incomeLoss: validateAndRoundNumberForResult(recipeRawLoss.freeFatMilkOutputWeight),
      balanceLoss: validateAndRoundNumberForResult(recipeRawLoss.freeFatMilkCurrentWeight),
      consumptionLoss: 0,
    },
    {
      material: "Творожная сыворотка",
      income: validateAndRoundNumberForResult(recipeRaw.cottageCheeseWheyWeight),
      balance: validateAndRoundNumberForResult(recipeRaw.cottageCheeseWheyWeight),
      consumption: 0,
      incomeLoss: validateAndRoundNumberForResult(recipeRawLoss.cottageCheeseWheyWeight),
      balanceLoss: validateAndRoundNumberForResult(recipeRawLoss.cottageCheeseWheyWeight),
      consumptionLoss: 0,
    },
    {
      material: "Подсырная сыворотка",
      income: validateAndRoundNumberForResult(recipeRaw.cheeseWheyWeight),
      balance: validateAndRoundNumberForResult(recipeRaw.cheeseWheyWeight),
      consumption: 0,
      incomeLoss: validateAndRoundNumberForResult(recipeRawLoss.cheeseWheyWeight),
      balanceLoss: validateAndRoundNumberForResult(recipeRawLoss.cheeseWheyWeight),
      consumptionLoss: 0,
    },
    {
      material: "Пахта",
      income: validateAndRoundNumberForResult(recipeRaw.butterMilkWeightFromHiFatProducts),
      balance: validateAndRoundNumberForResult(recipeRaw.butterMilkWeightFromHiFatProducts),
      consumption: 0,
      incomeLoss: validateAndRoundNumberForResult(recipeRawLoss.butterMilkWeightFromHiFatProducts),
      balanceLoss: validateAndRoundNumberForResult(recipeRawLoss.butterMilkWeightFromHiFatProducts),
      consumptionLoss: 0,
    },
  ]
}
