import * as XLSX from "xlsx"

import { formatDateAndTime } from "./dateTime"

export const exportTable = ({
  data,
  fileName,
}: {
  data: Record<string, string | number | undefined>[]
  fileName: string
}) => {
  const downloadXLSX = () => {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(data)

    // Set column widths and apply left alignment
    const colHeaders = Object.keys(data[0])
    colHeaders.forEach((header, colIndex) => {
      const maxContentWidth = data.reduce(
        (maxWidth, row) => Math.max(maxWidth, row[header]?.toString().length || 0),
        0,
      )
      const headerWidth = header.length
      const colWidth = Math.max(headerWidth, maxContentWidth) + 2

      ws["!cols"] = ws["!cols"] || []
      ws["!cols"][colIndex] = { wch: colWidth }

      // Apply left alignment to each cell in the column
      data.forEach((_, rowIndex) => {
        const cellRef = XLSX.utils.encode_cell({ c: colIndex, r: rowIndex + 1 }) // +1 to skip the header row
        ws[cellRef] = ws[cellRef] || {}
        ws[cellRef].s = {
          alignment: {
            horizontal: "left",
          },
        }
      })
    })

    XLSX.utils.book_append_sheet(wb, ws, fileName)
    XLSX.writeFile(wb, `${fileName} ${formatDateAndTime(new Date())}.xlsx`)
  }
  downloadXLSX()
}
