import { persistentAtom } from "@nanostores/persistent"
import { UserDto } from "api/model"
import { atom, computed } from "nanostores"

const persistentOptions = {
  encode: JSON.stringify,
  decode: JSON.parse,
}

const $token = persistentAtom<string | undefined>("token", undefined, persistentOptions)

const removeToken = () => {
  $token.set(undefined)
}

const setToken = (token: string) => {
  $token.set(token)
}

const $isAuthModalOpen = atom<boolean>(false)

const openAuthModal = () => {
  $isAuthModalOpen.set(true)
}

const closeAuthModal = () => {
  $isAuthModalOpen.set(false)
}

export type Role = "master" | "technologist" | "admin"

export type User = Omit<UserDto, "roles"> & { roles: Role[] }

const $user = persistentAtom<User | undefined>("user", undefined, persistentOptions)

const setUser = (user: UserDto) => {
  const roles = (user?.roles?.split(" ") ?? []) as Role[]
  const formattedUser: User = { ...user, roles }

  $user.set(formattedUser)
}

const removeUser = () => {
  $user.set(undefined)
}

const logout = () => {
  removeToken()
  removeUser()
}

const $isLoggedIn = computed([$token], (token) => Boolean(token))

const $isAdmin = computed([$user], (user) => user?.roles.includes("admin") ?? false)

export const auth = {
  $token,
  $user,
  $isAuthModalOpen,
  $isLoggedIn,
  $isAdmin,
  setToken,
  setUser,
  openAuthModal,
  closeAuthModal,
  logout,
}
