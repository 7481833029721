import { Measure, RecipeBasicMaterialDto, RecipePackagingMaterialDto } from "api/model"

const getNameAndMeasure = (el: RecipePackagingMaterialDto | RecipeBasicMaterialDto) => {
  const result: { name: string; measure: Measure } = {
    name: "KILOGRAM",
    measure: "KILOGRAM",
  }

  if ("packagingMaterial" in el) {
    result.name = el.packagingMaterial?.name ?? ""
    result.measure = (el.packagingMaterial?.measure ?? "KILOGRAM") as Measure
  } else if ("basicMaterial" in el) {
    result.name = el.basicMaterial?.name ?? ""
    result.measure = (el.basicMaterial?.measure ?? "KILOGRAM") as Measure
  }

  return result
}

export const formatMaterialsData = (
  materialsData: RecipePackagingMaterialDto[] | RecipeBasicMaterialDto[],
) => {
  return materialsData.map((el) => ({
    consumption: el.expenditure?.amount ?? 0,
    loss: el.expenditure?.lossPercentage ?? 0,
    ...getNameAndMeasure(el),
  }))
}
