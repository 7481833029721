import { Measure, RecipeBasicMaterialDto, RecipePackagingMaterialDto } from "api/model"
import { RecipeAssortment, InputData } from "features/recipesCalculator/types"
import { measureLocales } from "shared/constants/materials/measureOptions"
import * as XLSX from "xlsx"

export const createRecipeMaterialsData = ({
  materials,
  inputData,
  assortments,
  title,
}: {
  materials: RecipeBasicMaterialDto[] | RecipePackagingMaterialDto[]
  inputData?: InputData
  assortments: RecipeAssortment[]
  title: string
}) => {
  const data = [
    ["СЫРЬЕВОЙ БАЛАНС", ""],
    [],
    ["I. Исходные данные:"],
    ["Молоко сырое, количество, кг:", inputData?.rawMilkQuantity?.toFixed(2) || 0],
    ["Молоко сырое, МДЖ, %:", inputData?.rawMilkFat?.toFixed(2) || 0],
    ["МДЖ сепарированных сливок, %:", inputData?.creamFat?.toFixed(2) || 0],
    ["МДЖ обрата, %:", inputData?.freeFatMilkFat?.toFixed(2) || 0],
    ["МДЖ пахты, %:", inputData?.butterMilkFat?.toFixed(2) || 0],
    [],
    ["II. Ассортимент"],
    ...assortments.map((obj) => {
      return [
        `${obj.product.name} (${obj.product.fatPercentage}%):`,
        obj.currentWeight,
        `Рецепт № ${obj.selectedRecipe?.num}`,
        obj.normalizationMethod === "stream" ? "нормализация в потоке" : "объёмная нормализация",
      ]
    }),
    [],
    [`III. ${title}`, "", ""],
    ["Наименование", "Расход без учета потерь", "Расход с учетом потерь"],
    ...materials.map((el) => {
      if ("basicMaterial" in el) {
        const unit = measureLocales[(el.basicMaterial?.measure || "KILOGRAM") as Measure]
        return [
          el.basicMaterial?.name ?? "",
          `${el.expenditure?.amount} ${unit}`,
          el.expenditure?.lossPercentage ? `${el.expenditure?.lossPercentage} ${unit}` : "-",
        ]
      } else if ("packagingMaterial" in el) {
        const unit = measureLocales[(el.packagingMaterial?.measure || "KILOGRAM") as Measure]
        return [
          el.packagingMaterial?.name ?? "",
          `${el.expenditure?.amount} ${unit}`,
          el.expenditure?.lossPercentage ? `${el.expenditure?.lossPercentage} ${unit}` : "-",
        ]
      } else {
        return []
      }
    }),
  ]

  return data
}

export const exportRecipeMaterialsToExcel = (data: (string | number)[][], fileName = "test") => {
  const ws = XLSX.utils.aoa_to_sheet(data)
  ws["!cols"] = [
    { wch: Math.max(40, 40) },
    { wch: Math.max(22, 22) },
    { wch: Math.max(22, 22) },
    { wch: Math.max(14, 14) },
    { wch: Math.max(14, 14) },
  ]

  const centerAlignment = { horizontal: "center", vertical: "center" }
  data.forEach((row, rowIndex) => {
    row.forEach((cell, colIndex) => {
      if (colIndex > 0) {
        const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
        if (!ws[cellRef]) ws[cellRef] = { t: "s", v: cell }
        ws[cellRef].s = {
          alignment: centerAlignment,
        }
      }
    })
  })

  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Export")
  XLSX.writeFile(wb, fileName + ".xlsx")
}
