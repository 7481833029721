import { DairyProcessingMetrics } from "../../types"

/**
 * @description Обновление веса и объема ингредиентов при производстве продукции средней жирности в потоке
 */
export const calculateForMediumFatProductsInStream = ({
  state,
  _currentFat,
  freeFatMilkFat,
  _currentWeight,
  creamFat,
}: {
  state: DairyProcessingMetrics
  _currentFat: number
  freeFatMilkFat: number
  _currentWeight: number
  creamFat: number
}): DairyProcessingMetrics => {
  const creamWeightIteration =
    (_currentWeight * (_currentFat - freeFatMilkFat)) / (creamFat - freeFatMilkFat)
  const freeFatMilkVolumeForMediumFatProductsIteration = _currentWeight - creamWeightIteration
  const rawMilkWeightIteration = 0
  return {
    ...state,
    rawMilkWeightIteration, //'сырое молоко не используем
    //'разводим сливки из сепаратора обратом
    creamWeightIteration,
    //'нужная масса обрата
    freeFatMilkVolumeForMediumFatProductsIteration,
    freeFatMilkVolumeForMediumFatProducts:
      state.freeFatMilkVolumeForMediumFatProducts + freeFatMilkVolumeForMediumFatProductsIteration,
    creamWeightForMediumFatProducts: state.creamWeightForMediumFatProducts + creamWeightIteration,
    rawMilkWeightForMediumFatProducts:
      state.rawMilkWeightForMediumFatProducts + rawMilkWeightIteration,
  }
}
