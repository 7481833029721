export const measureLocales = {
  KILOGRAM: "кг",
  GRAM: "г",
  LITER: "л",
  UNIT: "у.е.",
  PIECE: "шт",
  METER: "м",
} as const

export const measureOptions = Object.entries(measureLocales).map(([value, label]) => ({
  value,
  label,
}))
