import { ReactNode } from "react"

import { Button } from "antd"

export const TabContentWrapper = ({
  children,
  exportHandler,
}: {
  children: ReactNode
  exportHandler?: () => void
}) => (
  <div
    style={{
      border: "1px solid #f0f0f0",
      borderTop: "none",
      padding: "24px",
      marginTop: "-16px",
    }}
  >
    {children}

    <Button onClick={exportHandler} style={{ marginTop: 32 }}>
      Экспорт в Excel
    </Button>
  </div>
)
