import { ColumnsType } from "antd/es/table"

const baseColumnProps = {
  align: "center" as const,
  width: 140,
}

export const createRecipeRawTable = (showLoss: boolean) => {
  const loss = {
    title: "С учетом потерь",
    children: [
      {
        title: "Приход",
        dataIndex: "incomeLoss",
        key: "incomeLoss",
        ...baseColumnProps,
      },
      {
        title: "Расход",
        dataIndex: "consumptionLoss",
        key: "consumption",
        ...baseColumnProps,
      },
      {
        title: "Остаток",
        dataIndex: "balanceLoss",
        key: "balance",
        ...baseColumnProps,
      },
    ],
  }

  const base = {
    title: "Без учета потерь",
    children: [
      {
        title: "Приход",
        dataIndex: "income",
        key: "income",
        ...baseColumnProps,
      },
      {
        title: "Расход",
        dataIndex: "consumption",
        key: "consumption",
        ...baseColumnProps,
      },
      {
        title: "Остаток",
        dataIndex: "balance",
        key: "balance",
        ...baseColumnProps,
      },
    ],
  }

  const root = [
    {
      title: "",
      key: "material",
      dataIndex: "material",
    },
  ] as ColumnsType<object>

  root.push(base)

  if (showLoss) {
    root.push(loss)
  }

  return root
}
