import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { CalculatorOutlined, CaretLeftOutlined } from "@ant-design/icons"
import { useStore } from "@nanostores/react"
import { Button, Form, InputNumber, Space, Typography } from "antd"
import { useSyncAssortments } from "features/recipesCalculator/lib/useSyncAssortments"
import { recipesCalculator } from "features/recipesCalculator/model"
import { RecipeCalculationResults } from "features/recipesCalculator/ui/RecipeCalculationResults"
import { clientModuleRoutes } from "shared/constants/routes"

import styles from "./RecipesCalculator.module.scss"

const { Title } = Typography
const { Item } = Form

export const RecipesCalculator = () => {
  const status = useStore(recipesCalculator.$status)
  const inputData = useStore(recipesCalculator.$inputData)
  const isButterMilkFatIsDisabled = useStore(recipesCalculator.$isButterMilkFatIsDisabled)
  const isCalculationButtonIsDisabled = useStore(recipesCalculator.$isCalculationButtonIsDisabled)
  const isFreeFatMilkFatIsDisabled = useStore(recipesCalculator.$isFreeFatMilkFatIsDisabled)

  const navigate = useNavigate()

  const [form] = Form.useForm()

  useSyncAssortments()

  useEffect(() => {
    recipesCalculator.clearCalculation()
    recipesCalculator.clearInputData()
  }, [])

  return (
    <Space direction='vertical'>
      <Space>
        <Title level={2}>Расчет рецептов</Title>
      </Space>
      <Space direction='vertical'>
        <Title level={3}>Исходные данные</Title>
        <Form form={form} className={styles.input_data_form}>
          <Form component='div' labelCol={{ span: 9 }} layout='horizontal'>
            <Title level={4}>Сырое молоко:</Title>
            <Space direction='vertical'>
              <Item label='Количество, кг' required>
                <InputNumber
                  style={{ width: 200 }}
                  name='rawMilkQuantity'
                  onChange={(value) => {
                    recipesCalculator.onInputChange({ name: "rawMilkQuantity", value })
                  }}
                  value={inputData.rawMilkQuantity}
                  placeholder='Количество, кг'
                  decimalSeparator=','
                  precision={2}
                  min={0}
                />
              </Item>
              <Item label='МДЖ, %' required>
                <InputNumber
                  style={{ width: 200 }}
                  name='rawMilkFat'
                  onChange={(value) =>
                    recipesCalculator.onInputChange({ name: "rawMilkFat", value })
                  }
                  value={inputData.rawMilkFat}
                  placeholder='МДЖ, %'
                  decimalSeparator=','
                  precision={2}
                  max={10}
                  min={0}
                />
              </Item>
            </Space>
          </Form>
          <Form component='div' layout='horizontal' labelCol={{ span: 14 }}>
            <Title level={4}>Параметры:</Title>
            <Item label='МДЖ сепарированных сливок, %' required>
              <InputNumber
                style={{ width: 200 }}
                name='creamFat'
                onChange={(value) => recipesCalculator.onInputChange({ name: "creamFat", value })}
                value={inputData.creamFat}
                placeholder='МДЖ сепарированных сливок, %'
                decimalSeparator=','
                max={65}
                precision={2}
                min={0}
              />
            </Item>
            <Item label='МДЖ обрата, %' required={!isFreeFatMilkFatIsDisabled}>
              <InputNumber
                style={{ width: 200 }}
                name='freeFatMilkFat'
                onChange={(value) =>
                  recipesCalculator.onInputChange({ name: "freeFatMilkFat", value })
                }
                value={inputData.freeFatMilkFat}
                placeholder='МДЖ обрата, %'
                decimalSeparator=','
                precision={2}
                max={1.5}
                disabled={isFreeFatMilkFatIsDisabled}
              />
            </Item>
            <Item label='МДЖ пахты, %' required={!isButterMilkFatIsDisabled}>
              <InputNumber
                style={{ width: 200 }}
                name='butterMilkFat'
                onChange={(value) =>
                  recipesCalculator.onInputChange({ name: "butterMilkFat", value })
                }
                value={inputData.butterMilkFat}
                placeholder='МДЖ пахты, %'
                decimalSeparator=','
                precision={2}
                max={4}
                disabled={isButterMilkFatIsDisabled}
                min={0.01}
              />
            </Item>
          </Form>
        </Form>
        <Button
          onClick={recipesCalculator.doCalculate}
          style={{ marginTop: 30, marginBottom: 30 }}
          type='primary'
          icon={<CalculatorOutlined />}
          htmlType='submit'
          disabled={isCalculationButtonIsDisabled}
        >
          Произвести расчёт
        </Button>
      </Space>

      {status === "calculated" && <RecipeCalculationResults title='Результаты расчета' />}

      <Space style={{ marginTop: 30 }}>
        <Button
          onClick={() => {
            navigate(clientModuleRoutes.recipes.assortment.root)
          }}
          type='primary'
          icon={<CaretLeftOutlined />}
        >
          Изменить ассортимент
        </Button>
      </Space>
    </Space>
  )
}
